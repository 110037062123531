import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import './style.scss';

class StaticPage extends Component {

  constructor (props) {
    super(props);
      this.state = {
    };
  }


  render(){
    return (
      <div id="StaticPage" className="pagewrap">
        <Helmet>
          <title>Grit Digital Health | University of Oregon</title>
          <link rel="canonical" href="https://www.gritdigitalhealth.com/university-of-oregon" />
          <meta name="description" content='The link provided to use by the research team will enable Nod download on your mobile device only, please click the link from your primary mobile device.' />
          <meta name="twitter:card" value="summary" />
          <meta property="og:title" content='Trying to download Nod for the research study?' />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://www.gritdigitalhealth.com/university-of-oregon" />
          <meta property="og:description" content='The link provided to use by the research team will enable Nod download on your mobile device only, please click the link from your primary mobile device.' />
        </Helmet>
        {/* Home Top Feature */}

        <div className="lockup">
          <div className="image hidden">
            <img className="desktop" src='/img/phone-UofO.png' alt='phone lockup'/>
          </div>
          <div className="content hidden">
            <div className="type">
              <img className="desktop" src='/img/UofO-logo.svg' alt='university of oregon'/>
              <h2>Trying to download Nod for the research study?</h2>
              <p>Thank you for participating in the University of Oregon Nod research study. The link provided to use by the research team will enable Nod download on your mobile device only, please click the link from your primary mobile device.</p>
            </div>
          </div>
          <br />
        </div>

      </div>
    );
  }
}

export default StaticPage;
