import { Helmet } from "react-helmet";
import React, { useState, useMemo } from "react";
import { Share } from "./share";
import gritLogo from "./img/grit-logo-black.svg";
import stars from "./img/stars.svg";
import { TipCard } from "./card";
import { Footer } from "./footer";
import qs from "qs";

import "./reset.scss";
import "./common.scss";
import "./style.scss";

const Tips = (props) => {
  const acc = 12;
  const shuffle = (arr) => {
    return arr.sort(() => Math.random() - 0.5);
  };

  let data = Object.entries(props.initData.tips);

  const shuffledData = useMemo(() => {
    return shuffle(data);
  }, []);

  const env = process.env.ENVIRONMENT ? process.env.ENVIRONMENT : "staging";

  const siteRoot =
    env === "staging"
      ? "https://gritdev.netlify.app"
      : "https://gritdigitalhealth.com";

  const filterParam =
    qs.parse(props.location.search, { ignoreQueryPrefix: true }).cat ||
    "all-tips";

  const [idx, setIdx] = useState(acc);
  const [currentFilter, setCurrentFilter] = useState(filterParam);
  const [hide, setHide] = useState(false);
  const [filterButtonsArr, setFilterButtonsArr] = useState([
    {
      id: "all-tips",
      title: "All Tips",
      active: filterParam === "all-tips",
    },
    {
      id: "feeling-jolly",
      title: "Feeling Jolly",
      active: filterParam === "feeling-jolly",
    },
    {
      id: "feeling-cozy",
      title: "Feeling Cozy",
      active: filterParam === "feeling-cozy",
    },
    {
      id: "feeling-blue",
      title: "Feeling Blue",
      active: filterParam === "feeling-blue",
    },
  ]);

  const renderCards = (cards) => {
    return cards.map((item, index) => {
      return <TipCard key={index} tip={item[1]} />;
    });
  };

  const [output, setOutput] = useState(
    filterParam === "all-tips"
      ? renderCards(shuffledData.slice(0, idx))
      : renderCards(
          shuffledData
            .filter((o) => o[1]["category"] === filterParam)
            .slice(0, idx)
        )
  );

  const FilterButton = ({ title, id, eventHandler, active }) => {
    return (
      <button
        onClick={eventHandler}
        className={`filter button ${active ? "active" : "not-active"}`}
        id={id}
      >
        {title}
      </button>
    );
  };

  const handleClick = (e) => {
    const activeButton = e.currentTarget.id;

    setHide(true);
    setCurrentFilter(activeButton);
    setFilterButtonsArr([
      {
        id: "all-tips",
        title: "All Tips",
        active: activeButton === "all-tips",
      },
      {
        id: "feeling-jolly",
        title: "Feeling Jolly",
        active: activeButton === "feeling-jolly",
      },
      {
        id: "feeling-cozy",
        title: "Feeling Cozy",
        active: activeButton === "feeling-cozy",
      },
      {
        id: "feeling-blue",
        title: "Feeling Blue",
        active: activeButton === "feeling-blue",
      },
    ]);

    setTimeout(() => {
      if (activeButton === "all-tips") {
        setOutput(renderCards(shuffledData.slice(0, idx)));
      } else {
        setOutput(
          renderCards(
            shuffledData
              .filter((o) => o[1]["category"] === activeButton)
              .slice(0, idx)
          )
        );
      }
      setHide(false);
    }, 300);
  };

  const filterButtons = filterButtonsArr.map((item, index) => {
    return (
      <FilterButton
        id={item.id}
        key={index}
        active={item.active}
        title={item.title}
        eventHandler={handleClick}
      />
    );
  });

  const handleMore = () => {
    if (currentFilter === "all-tips") {
      setOutput(renderCards(shuffledData.slice(0, idx + acc)));
    } else {
      setOutput(
        renderCards(
          shuffledData
            .filter((o) => o[1]["category"] === currentFilter)
            .slice(0, idx + acc)
        )
      );
    }
    setIdx(idx + acc);
  };

  const availableTipsInCategory = {
    "all-tips": data.length,
    "feeling-jolly": shuffledData.filter(
      (o) => o[1]["category"] === "feeling-jolly"
    ).length,
    "feeling-cozy": shuffledData.filter(
      (o) => o[1]["category"] === "feeling-cozy"
    ).length,
    "feeling-blue": shuffledData.filter(
      (o) => o[1]["category"] === "feeling-blue"
    ).length,
  };

  return (
    <div id="tips">
      <Helmet>
        <title>{props.initData.tips_page.meta_title}</title>
        <link rel="canonical" href={`${siteRoot}/tips`} />
        <meta
          name="description"
          content={props.initData.tips_page.meta_description}
        />
        <meta name="twitter:card" value="summary" />
        <meta
          property="og:title"
          content={props.initData.tips_page.meta_title}
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${siteRoot}/tips`} />
        <meta
          property="og:image"
          content={props.initData.tips_page.social_image}
        />
        <meta
          property="og:description"
          content={props.initData.tips_page.meta_description}
        />
      </Helmet>

      <header>
        <div className="container">
          <div className="navigation">
            <div className="left"></div>
            <Share
              title="Share Website"
              color="white"
              href={`http://www.facebook.com/sharer/sharer.php?u=https://${
                env === "staging"
                  ? "gritdev.netlify.app"
                  : "gritdigitalhealth.com"
              }/tips`}
            />
          </div>
          <div className="flex stars-wrap relative">
            <img
              className="stars"
              height="38px"
              width="47px"
              alt="stars"
              src={stars}
            />

            <div className="flourish top left"></div>
            <div className="flourish top right"></div>
          </div>
          <h1>Grit Digital Health</h1>
          <h2>Holiday Well-Being Tips</h2>
          <div className="subhead">
            <p>
              No matter how you spend it, the holiday season is a great time to
              get cozy, harness joy, embrace reflection, and focus on your
              well-being.
            </p>
          </div>
        </div>
      </header>

      <main>
        <div className="container" style={{ maxWidth: "1300px" }}>
          <div className="filter-wrap">{filterButtons}</div>
          <div className={`tips-index ${hide ? "hide" : "show"}`}>{output}</div>
        </div>
      </main>

      <section className="bottom">
        <div className="container">
          {idx < availableTipsInCategory[currentFilter] ? (
            <button className="button active view-more" onClick={handleMore}>
              View More Tips
            </button>
          ) : (
            ""
          )}
        </div>
      </section>
      <div className="container" style={{ marginBottom: "50px" }}>
        <div className="flourish bottom left"></div>
        <div className="flourish bottom right"></div>
      </div>

      <Footer href="/" logo={gritLogo} />
    </div>
  );
};

export default Tips;
