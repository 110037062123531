import React from 'react';
import Slider from "react-slick";
require('./style.scss');

//create slider wrapper and call the slide builder
function SimpleSlider(props) {

  //Set base slider settings
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: false,
    appendDots: dots => (<div className="counter">
      <ul>
        {dots}
      </ul>
    </div>),
    customPaging: i => (<div className="item">
      {i + 1}
      <span>/</span>{props.data.length}
    </div>)
  };

  return (<Slider {...settings} className="slider">
    {SlideBuilder(props)}
  </Slider>);
}

//build out the individual slides
function SlideBuilder(props) {
  var slideData = props.data;
  var slideBuild = [];
  for (var key in slideData) {
    slideBuild.push(<div key={key}>
      <img className="desktop-img" src={slideData[key].image_rotator.sizes.Maximum} alt="slide"/>
    </div>)
  }
  return slideBuild;
}

export default SimpleSlider;
