import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import Slider from '../Slider';
import VimeoPlayer from '../VimeoPlayer';
import './style.scss';

class Posts extends Component {

  constructor() {
    super();
    this.state = {
      post: [],
      isLoading: true,
      currentUrl: window.location.href
    };
    console.log('wtf are we here')
  }

  componentDidMount() {
    let pathName = window.location.pathname.split('/')[2];
    fetch(`https://test-grit-cms.pantheonsite.io/wp-json/wp/v2/posts?slug=${pathName}`).then(result => result.json()).then(post => this.setState({post, isLoading: false}));
  }

  getContent() {
    const example_data = this.state.post[0].acf.postmedia;
    var examples = [];
    for (var path in example_data) {
      if (example_data[path].acf_fc_layout === 'video') {
        examples.push(<div className="video-wrap" key={path}>
          <VimeoPlayer videoID={example_data[path].video_url}/>
        </div>);
      } else if (example_data[path].acf_fc_layout === 'full_width_image') {
        examples.push(<div className="image-wrap" key={path}>
          <img className="desktop-img" src={example_data[path].fw_image.sizes.Maximum} alt="work-example"/>
          <img className="mobile-img" src={example_data[path].fw_image.sizes.medium_large} alt="work-example"/>
        </div>);
      } else if (example_data[path].acf_fc_layout === 'content_width_image') {
        examples.push(<div className="image-wrap content-width" key={path}>
          <img className="desktop-img" src={example_data[path].content_image.sizes.large} alt="work-example"/>
          <img className="mobile-img" src={example_data[path].content_image.sizes.medium_large} alt="work-example"/>
        </div>);
      } else if (example_data[path].acf_fc_layout === 'image_rotator') {
        var rotator_data = example_data[path].rotator;
        examples.push(<Slider data={rotator_data} settings="" key={path}/>)
      } else if (example_data[path].acf_fc_layout === 'video_box') {
        examples.push(<div className="video-boxed" key={path}>
          <VimeoPlayer videoID={example_data[path].video_url}/>
        </div>);
      } else if (example_data[path].acf_fc_layout === 'text_block') {
        examples.push(<div className="text_block" key={path}>
          <div className="content-wrap" dangerouslySetInnerHTML={{
              __html: example_data[path].text_block
            }}/>
        </div>);
      } else if (example_data[path].acf_fc_layout === 'loop_video') {
        examples.push(<div className="video-wrap" key={path}>
          <VimeoPlayer videoID={example_data[path].video_id}/>
        </div>);
      }
    }
    return examples;
  }

  getRelatedNews() {
    const news_data = this.state.post[0].acf.related_news;
    //console.log(news_data);
    var news = [];
    for (var key in news_data) {
      news.push(<Link className="news-card" key={key} to={'/ideas/' + news_data[key].post_item.post_name}>
        <div className={news_data[key].featured_image
            ? "featured_image"
            : "hide"}>
          <img className="desktop-img" src={news_data[key].featured_image
              ? (news_data[key].featured_image.sizes.medium_large)
              : (undefined)} alt="grit"
              />
          <img className="mobile-img" src={news_data[key].featured_image
              ? (news_data[key].featured_image.sizes.medium_large)
              : (undefined)} alt="grit"
              />
        </div>
        <div className="content">
          <h4>{news_data[key].post_item.post_title}</h4>
          <p>{news_data[key].post_item.post_excerpt}</p>
          <div className="case-study-link"></div>
        </div>
      </Link>);
    }
    return news;
  }

  getRelatedSection() {
    var newsSection = [];
    const news_data = this.state.post[0].acf.related_news;
    for (var key in news_data) {
      newsSection.push(<div key={key} className="related-posts">
        <div className="related-wrap">
          <h3>More Grit News</h3>
          <div className="content-wrap">
            {this.getRelatedNews()}
          </div>
        </div>
      </div>)
    }
    return newsSection;
  }

  render() {
    let postData = this.state.post[0];
    const {currentUrl} = this.state;
    //console.log(this.state.post);

    return (<div id="posts" className="pagewrap">
      {
        this.state.isLoading
        //show nothing - the data from WP hasn't loaded yet so data is returned as undefined and the app errors out.
      } {
        !this.state.isLoading &&
        //Data loaded - it's go time - render the following content.
        <div>
            <Helmet>
              <title>{postData.acf.meta_title}</title>
              <link rel="canonical" href={currentUrl}/>
              <meta name="description" content={postData.acf.meta_description}/>
              <meta name="twitter:card" value="summary"/>
              <meta property="og:title" content={postData.acf.meta_title}/>
              <meta property="og:type" content="article"/>
              <meta property="og:url" content={currentUrl}/>
              <meta property="og:image" content={postData.acf.social_image}/>
              <meta property="og:description" content={postData.acf.meta_description}/>
            </Helmet>
            <div className={'content-wrap'}>
              <div className="crumb-link">
                <Link to="/ideas">
                  <span>News & Ideas</span>
                </Link>
              </div><br/>
              <h1 dangerouslySetInnerHTML={{
                  __html: postData.title.rendered
                }} className="styled"/> {/* <Button path="#hire-us" text={'skip to Hire Us'} className={'text-link'} /> */}
            </div>
            <div className="body">
              {this.getContent()}
              {this.getRelatedSection()}
            </div>
          </div>
      }

    </div>);
  }
}

export default Posts;
