// import React, { Component } from "react";
import { Helmet } from "react-helmet";
import React, { useState, useRef } from "react";
import { gratitudeMessages } from "./gratitudeMessages";
import "./style.scss";

const Gratitude = props => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [borderState, setBorderState] = useState({
    isActive: false,
    isFlipped: false
  });
  const [isScaled, setIsScaled] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [messageIndex, setMessageIndex] = useState(0);
  const [currentMessage, setCurrentMessage] = useState({
    message: "Thank someone for turning you on to something new",
    image: "parrot.png"
  });

  const bkgRef = useRef(null);
  const imgBase = 'https://res.cloudinary.com/cactusmedia/image/upload/c_scale,q_auto:good/grit/gratitude/';

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const calcBorderState = () => {
    const isActive = borderState.isActive ? "active" : "";
    const isFlipped = borderState.isFlipped ? "flip" : "";
    return `border ${isActive} ${isFlipped} `;
  };

  const animateCover = () => {
    setBorderState({ isFlipped: borderState.isFlipped, isActive: true });

    //expand
    setIsCollapsed(false);

    // then flip
    setTimeout(function() {
      setBorderState({ isFlipped: !borderState.isFlipped, isActive: true });
      setIsScaled(false);
    }, 300);

    // then make image scale larger slightly
    setTimeout(function() {
      setIsScaled(true);
    }, 800);

    // then collaspse
    setTimeout(function() {
      setIsCollapsed(true);
      setBorderState({ isFlipped: !borderState.isFlipped, isActive: false });
    }, 900);
  };

  const onClick = () => {
    animateCover();
    setIsInit(false);

    //Scroll to component on desktop only (mobile is too abrupt and unsettling haha)
    setTimeout(function() {
      if (typeof window !== "undefined" && window.outerWidth > 550) {
        bkgRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 600);

    //Once animation is done, render message
    setTimeout(function() {
      getNewMessage();
    }, 800);

    const getNewMessage = () => {
      const getUniqueIndex = (prev, current) => {
        if (prev === current) {
          return getUniqueIndex(
            prev,
            getRandomInt(0, gratitudeMessages.length - 1)
          );
        } else {
          return current;
        }
      };
      const index = getUniqueIndex(
        messageIndex,
        getRandomInt(0, gratitudeMessages.length - 1)
      );

      const newMessage = gratitudeMessages[index].copy;
      const newImage = gratitudeMessages[index].image;

      setCurrentMessage({ message: newMessage, image: newImage });
      setMessageIndex(index);
    };
  };

  return (
    <div id="gratitude" className="pagewrap">
      <Helmet>
        <title>Get grateful with the gratitude generator</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/gratitude" />
        <meta
          name="description"
          content="Gratitude is a win-win-win for your happiness, health and relationships. Generate some good vibes and get appreciating."
        />

        <meta name="twitter:card" value="summary" />
        <meta
          property="twitter:title"
          content="Get grateful with the gratitude generator"
        />
        <meta property="twitter:type" content="business" />
        <meta
          property="twitter:description"
          content="Gratitude is a win-win-win for your happiness, health and relationships. Generate some good vibes and get appreciating."
        />

        <meta
          property="og:title"
          content="Get grateful with the gratitude generator"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={"https://www.gritdigitalhealth.com/gratitude"}
        />
        <meta
          property="og:image"
          content="https://www.gritdigitalhealth.com/img/gratitude/share-grit.jpg"
        />
        <meta
          property="og:description"
          content="Gratitude is a win-win-win for your happiness, health and relationships. Generate some good vibes and get appreciating."
        />
      </Helmet>

      <div className="content-wrap intro">
        <h1 className="styled">Share the gift of gratitude</h1>
        <h3>
          Did you know that tapping into gratitude can improve health and
          happiness? Plus, it strengthens our relationships. Discover simple
          ways to share thanks.
        </h3>
      </div>
      <div className="general hidden">
        <div className="content-wrap">
          <div className="container">
            <section className="generator active">
              <div className={`bkg ${isInit ? "init" : ""}`} ref={bkgRef}>
                <div
                  className={`cover ${isCollapsed ? "collapsed" : "expanded"}`}
                >
                  <div className={calcBorderState()}></div>
                </div>

                <div className={`initial-state ${isInit ? "active" : ""}`}>
                  <h2>Click the button below to get an idea</h2>
                  <div className={`arrow ${isInit ? "active" : ""}`}></div>
                </div>
                <div className={`inner ${isInit ? "" : "active"}`}>
                  <div className="left box">
                    <div className={`ellipse ${isScaled ? "scale" : ""}`}></div>
                    <img
                      className={`main-image ${isScaled ? "scale" : ""}`}
                      src={`${imgBase}${currentMessage.image}`}
                      alt="grit gratitude"
                    />
                  </div>
                  <div className="right box">
                    <div className="idea-number">{`Idea #${messageIndex +
                      1}`}</div>
                    <h2 className="message">{currentMessage.message}</h2>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section className="more-button">
            <div className="container">
              <button
                type="button"
                name="button"
                onClick={onClick}
                className="button"
              >
                {isInit ? "Get Grateful" : "Get More Grateful"}
              </button>
            </div>
          </section>
          <section className="share-links">
            <div className="container">
              <h3>Don’t keep all these good vibes to yourself. Pass it on: </h3>
              <div className="social-wrap">
                <a
                  aria-label="linked in link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/shareArticle?url=https://www.gritdigitalhealth.com/gratitude&title=Gratitude%20Generator&summary=Gratitude%20Generator"
                >
                  <img src="img/Grit_LinkedIn_Icon.svg" alt="linked in icon" />
                </a>
                <a
                  aria-label="facebook link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.facebook.com/sharer/sharer.php?u=https://www.gritdigitalhealth.com/gratitude"
                >
                  <img src="img/Grit_Facebook_Icon.svg" alt="facebook icon" />
                </a>
                <a
                  aria-label="mail link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:?Subject=Get%20grateful%20with%20the%20gratitude%20generator&Body=Gratitude%20is%20a%20win-win-win%20for%20your%20happiness,%20health%20and%20relationships.%20Generate%20some%20good%20vibes%20and%20get%20appreciating.%20Visit%20gritdigitalhealth.com/gratitude/%20to%20try%20it%20out"
                >
                <div className='makeshift-mail-icon'></div>
                </a>
                <a
                  aria-label="twitter link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://twitter.com/share?url=https://www.gritdigitalhealth.com/gratitude"
                >
                  <img src="img/Grit_Twitter_Icon.svg" alt="twitter icon" />
                </a>
              </div>
            </div>
          </section>
          <section className="note">
            <div className="container">
              <p>
                This gratitude generator is brought to you by Grit Digital
                Health. Explore our{" "}
                <a href="https://gritdigitalhealth.com/solutions">
                  behavioral health and wellbeing solutions
                </a>{" "}
                or{" "}
                <a href="https://gritdigitalhealth.com/contact">get in touch</a>
                .
              </p>
            </div>
          </section>
          <section className="hidden-preload-images">
            {/* 'preload' images */}
            {gratitudeMessages.map((item, i) => (
              <img
                src={`${imgBase}${item.image}`}
                key={i}
                alt="grit gratitude"
              />
            ))}
          </section>

        </div>
      </div>
    </div>
  );
};

export default Gratitude;
