import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
require('./style.scss');

class ForOhFor extends Component {

  constructor() {
    super();
    this.state = {
      test: 'asdf'
    };
  }

  handleClick(e) {
    //chill
    console.log('button clicked');
    window.history.back();
  }

  render() {
    return (<div id="four" className="pagewrap">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>404</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/404"/>
      </Helmet>
      <div className="page-wrap">
        <div className="content-wrap">
          <div className="text-wrap">
            <h4>404 / Page Not Found</h4>
            <p>Uh oh, looks like something went wrong. If the address you've entered is correct, this page may not exist anymore. Since you're already here though give the rest of the site a gander.</p>
            <div className="text-link black back" onClick={this.handleClick.bind(this)}>Go Back</div>
            <Link to={'/'} className="text-link black">
              Go Home</Link>
          </div>
          <div className="fourohfour">
            <h1>Four<br/>Oh<br/>Four</h1>
          </div>
        </div>
      </div>
    </div>);
  }
}

export default ForOhFor;
