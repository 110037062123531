import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import FooterCallout from '../FooterCallout'
import './style.scss'

class Privacy extends Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    if (window.location.hash) {
      setTimeout(() => {
        var element_to_scroll_to = document.getElementById('privacy-policy')
        element_to_scroll_to.scrollIntoView()
      }, 1500)
    }
  }

  render() {
    console.log('props', this.props.initData.privacy)
    return (
      <div id="contact" className="pagewrap">
        <Helmet>
          <title>{this.props.initData.privacy.meta_title}</title>
          <link
            rel="canonical"
            href="https://www.gritdigitalhealth.com/privacy-policy"
          />
          <meta
            name="description"
            content={this.props.initData.privacy.meta_description}
          />
          <meta name="twitter:card" value="summary" />
          <meta
            property="og:title"
            content={this.props.initData.privacy.meta_title}
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={'https://www.gritdigitalhealth.com/privacy-policy'}
          />
          <meta
            property="og:image"
            content={this.props.initData.privacy.social_image}
          />
          <meta
            property="og:description"
            content={this.props.initData.privacy.meta_description}
          />
        </Helmet>
        <div className={'content-wrap intro'}>
          <h1 className="styled">{this.props.initData.privacy.post_title}</h1>
        </div>
        <div className="general hidden">
          <div className="content-wrap">
            <div
              id="privacy"
              dangerouslySetInnerHTML={{
                __html: this.props.initData.privacy.post_content
              }}
            />
          </div>
        </div>
        <FooterCallout
          calloutData={this.props.initData.contact.footer_callout}
        />
      </div>
    )
  }
}

export default Privacy
