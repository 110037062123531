import React, { useState } from "react";
import "./style.scss";

const ToolkitModal = props => {
  const [pw, setPw] = useState("");
  const [active, setActive] = useState(true);
  const [error, setError] = useState(false);

  const includePw = props.includePw;
  if (!includePw) {
    return null;
  }

  const onChange = e => {
    setPw(e.target.value);
    setError(false);
  };

  const onClick = e => {
    if (pw === props.pw) {
      setActive(false);
    } else {
      setError(true);
    }
  };

  const onKeyPress = e => {
    const key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
    if (key === 13) {
      onClick();
    }
  };

  return (
    <div
      className={active ? "toolkit-modal active" : "toolkit-modal"}
      onKeyPress={onKeyPress}
    >
      <div className="form-wrap">
        <div className="form">
          <h3>Restricted Access</h3>
          <p className="desc">
            The marketing toolkit you are trying to view is password protected.
            You will need to enter a password below to view the marketing
            toolkit. If you would like to gain access to this marketing toolkit
            please contact the site administrator by{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:annaliese@gritdigitalhealth.com?subject=Marketing-Toolkit-authorization-request"
            >
              clicking here.
            </a>
          </p>
          <div className="input">
            <label htmlFor="pw" className={pw !== "" ? "active" : ""}>
              Enter password{" "}
            </label>
            <input
              id="pw"
              type="password"
              className={error ? " form-control invalid" : "form-control"}
              onChange={onChange}
              value={pw}
              autoFocus
            />
          </div>
          <div className="error-wrap">
            <div className={error ? "errors active" : "errors"}>
              Please enter the correct password
            </div>
          </div>

          <div className="input">
            <button
              className="btn btn-default"
              type="submit"
              id="submit"
              onClick={onClick}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
//
// class ToolkitModal extends React.Component {
//   constructor(props) {
//     super(props);
//
//     this.onChange = this.onChange.bind(this);
//     this.onClick = this.onClick.bind(this);
//     this.onKeyPress = this.onKeyPress.bind(this);
//
//     this.state = {
//       pw: "",
//       active: true,
//       error: false
//     };
//   }
//
//   onChange(e) {
//     this.setState({
//       pw: e.target.value,
//       error: false
//     });
//   }
//
//   onClick(e) {
//     if (this.state.pw === config) {
//       this.setState({
//         active: false
//       });
//     } else {
//       this.setState({
//         error: true
//       });
//     }
//   }
//
//   onKeyPress(e) {
//     const key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
//     if (key === 13) {
//       this.onClick();
//     }
//   }
//
//   render() {
//     return (
//       <div
//         className={this.state.active ? "toolkit-modal active" : "toolkit-modal"}
//         onKeyPress={this.onKeyPress}
//       >
//         <div className="form-wrap">
//           <div className="form">
//             <h3>Restricted Access</h3>
//             <p className="desc">
//               The marketing toolkit you are trying to view is password protected. You
//               will need to enter a password below to view the marketing toolkit.
//               If you would like to gain access to this marketing toolkit please
//               contact the site administrator by{" "}
//               <a
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 href="mailto:andrew@gritdigitalhealth?subject=Marketing-Toolkit-authorization-request"
//               >
//                 clicking here.
//               </a>
//             </p>
//             <div className="input">
//               <label
//                 htmlFor="pw"
//                 className={this.state.pw !== "" ? "active" : ""}
//               >
//                 Enter password{" "}
//               </label>
//               <input
//                 id="pw"
//                 type="password"
//                 className={
//                   this.state.error ? " form-control invalid" : "form-control"
//                 }
//                 onChange={this.onChange}
//                 value={this.state.pw}
//                 autoFocus
//               />
//             </div>
//             <div className="error-wrap">
//               <div className={this.state.error ? "errors active" : "errors"}>
//                 Please enter the correct password
//               </div>
//             </div>
//
//             <div className="input">
//               <button
//                 className="btn btn-default"
//                 type="submit"
//                 id="submit"
//                 onClick={this.onClick}
//               >
//                 Submit
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

export default ToolkitModal;
