import React from 'react';
import Slider from "react-slick";
require('./style.scss');

//create slider wrapper and call the slide builder
function SimpleSlider(props) {

  //Set base slider settings
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: false,
    arrows: false,
    fade: true,
  };

  return (<Slider {...settings} className="slider">
    {SlideBuilder(props)}
  </Slider>);
}

//build out the individual slides
function SlideBuilder(props) {
  var slideData = props.data;
  var slideBuild = [];
  for (var key in slideData) {
    slideBuild.push(
      <div key={key}>
        <img className="desktop-img" src={slideData[key].hero_image.sizes.large} alt="slide"/>
      </div>
    )
  }
  return slideBuild;
}

export default SimpleSlider;
