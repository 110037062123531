import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

class TextImageLockup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lockupData: this.props.lockupData,
    };
  }

  getLockups(){
    var lockups = [];
    var data = this.props.lockupData;

    for (var key in data) {
      var type = data[key].link_url.post_type;
      lockups.push(
        <div className="lockup" key={key}>
          <div className="image hidden">
            <img className="desktop" src={data[key].lockup_image.sizes.large} alt={data[key].lockup_title}/>
            <img className="mobile" src={data[key].lockup_image.sizes.medium} alt={data[key].lockup_title}/>
          </div>
          <div className="content hidden">
            <div className="type">
              <h2>{data[key].lockup_title}</h2>
              <p>{data[key].lockup_body_copy}</p>
            </div>
          {type === 'post' ? <Link to={"news/" + data[key].link_url.post_name} className="button">{data[key].cta_text}</Link> : ''}
          {type === 'case_study' ? <Link to={"work/" + data[key].link_url.post_name} className="button">{data[key].cta_text}</Link> : ''}
          {type === 'products' ? <Link to={"products/" + data[key].link_url.post_name} className="button">{data[key].cta_text}</Link> : ''}
          {type === 'careers' ? <Link to={"careers/" + data[key].link_url.post_name} className="button">{data[key].cta_text}</Link> : ''}
          {type === 'experience' ? <Link to={"experience/" + data[key].link_url.post_name} className="button">{data[key].cta_text}</Link> : ''}
          {type === 'capabilities' ? <Link to={"capabilities/" + data[key].link_url.post_name} className="button">{data[key].cta_text}</Link> : ''}
          {type === 'team_member' ? <Link to={"team/" + data[key].link_url.post_name} className="button">{data[key].cta_text}</Link> : ''}
          </div>
          <br />
        </div>
      );
    }
    return lockups;
  }

  render() {
    return (
      <div className={"TextImageLockup"}>
        {this.getLockups()}
      </div>
    )
  }
}

export default TextImageLockup;
