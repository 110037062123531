import React from 'react';
import {Link} from 'react-router-dom';
import {has as _has} from 'lodash';
require('./style.scss');

function Button(props) {

  if (_has(props, 'path')) {
    return (<Link to={props.path} onClick={props.onButtonPress} className={'button ' + props.className}>{props.text}</Link>);
  } else {
    return (<button onClick={props.onButtonPress} className={'button ' + props.className}>{props.text}</button>);
  }
}

export default Button;
