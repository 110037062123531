import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import './style.scss';

class Industries extends Component {

  constructor() {
    super();
    this.state = {
      matched: false,
      post: {}
    };
  }

  componentWillMount() {
    const post_name = this.props.match.params.industry;
    const post = this.props.initData.industries[post_name];
    if (post)
      this.setState({matched: true, post: post});
    this.updateClass();
  }
  componentWillUnmountMount() {
    this.removeClass()
  }

  updateClass() { //add home page helper classes.
    var appEle = document.getElementById('app');
    var body = document.body;
    if (appEle.classList.contains('experience')) {
      //home class already exists on app, don't add it again
    } else {
      appEle.className += " experience";
      body.className += "experience";
    }
  }

  removeClass() {
    //remove all the experience page classes that help with the scroll jack rendering. This is fired on component unmount.
    var appEle = document.getElementById('app');
    appEle.classList.remove("experience");
  }

  getItems() {
    var Items = []
    this.state.post.logos.forEach(function(logo, index) {
      /* push final markup to array to be returned */
      Items.push(<div className={"logo"} key={index}>
        <div className="image" style={{
            backgroundImage: `url(${logo.client_image})`
          }}/>
      </div>);
    });
    return Items;
  }

  getExperiences() {
    var experience_links = [];
    const pageTitle = this.state.post.post_title;
    const experience_data = this.props.initData.industries;

    for (var key in experience_data) {
      var isActive = false;
      if (experience_data[key].post_title === pageTitle) {
        isActive = true;
      }
      experience_links.push(<Link key={key} to={"/experience/" + experience_data[key].post_name} className={isActive
          ? "active"
          : ""}>
        <span>{experience_data[key].post_title}</span>
      </Link>)
    }
    return experience_links;
  }

  getCaseStudies() {
    const case_study_data = this.state.post.featured_case_studies;
    var case_studies = [];
    var studyData = this.props.initData.case_studies;
    //Gather featured case study data from the main array and push out new arrays
    var createProject = function(id) {
      for (var i in studyData) {
        if (studyData[i].ID === id) {
          case_studies.push(<Link key={i} className="small" to={'/work/' + studyData[i].post_name}>
            <div className={"main-img"}>
              <img className="desktop-img" src={studyData[i].main_image.sizes.large} alt={studyData[i].post_title}/>
              <img className="mobile-img" src={studyData[i].main_image.sizes.medium_large} alt={studyData[i].post_title}/>
              <div className="container">
                <div className="case-study-link"/>
              </div>
            </div>
            <div className={'post-content'}>
              <h2>{studyData[i].post_title}</h2>
              <h4>{studyData[i].subhead_work_page}</h4>
            </div>
          </Link>)
        }
      }
    }
    //Find the ID of the featured Case Study and fire it off to the createProject function
    for (var key in case_study_data) {
      var caseID = case_study_data[key];
      createProject(caseID);
    }
    return case_studies;
  }

  render() {
    if (this.state.matched) {
      return (<div id="Industries" className="pagewrap">
        <Helmet>
          <title>{this.state.post.meta_title}</title>
          <link rel="canonical" href={"https://www.gritdigitalhealth.com/industries/" + this.state.post.post_name}/>
          <meta name="description" content={this.state.post.meta_description}/>
          <meta name="twitter:card" value="summary"/>
          <meta property="og:title" content={this.state.post.meta_title}/>
          <meta property="og:type" content="article"/>
          <meta property="og:url" content={"https://www.gritdigitalhealth.com/industries/" + this.state.post.post_name}/>
          <meta property="og:image" content={this.state.post.social_image}/>
          <meta property="og:description" content={this.state.post.meta_description}/>
        </Helmet>
        <div className="hero-wrap">
          <div className={'content-wrap'}>
            <div className="type-wrap">
              <Link to={'/capabilities'} className="crumb-link">
                <span>Capabilities</span>
              </Link><br/>
              <h1 className="styled">
                {this.state.post.post_title}
              </h1>
            </div>
          </div>
        </div>
        <div className="cac-pannel-wrap right desktop">
          <div className="cac-pannel"></div>
        </div>
        <div className="description">
          <div className="cac-pannel-wrap right mobile">
            <div className="cac-pannel"></div>
          </div>
          <div className="content-wrap">
            <div>
              <div className="body" dangerouslySetInnerHTML={{
                  __html: this.state.post.post_content
                }}/>
              <div className="client-logos-wrap">
                <div className="client-logos">
                  {this.getItems()}
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.post.featured_case_studies
            ? (<div className="featured-case-studies hidden">
              <div className="content-wrap">
                <h2 className="title">Work in this Category</h2>
                {this.getCaseStudies()}
              </div>
            </div>)
            : ('')
        }
        <div id="client-experience" className="hidden">
          <div className={'content-wrap'}>
            <div className={'experience-content'}>
              <h2>Take a look at our other client verticals.</h2>
              <p>We’ve had the opportunity to create for, collaborate with, and guide some of the world’s most important businesses across several categories.</p>
            </div>
            <div className="client-list">
              {this.getExperiences()}
              <br/>
            </div>
          </div>
        </div>
      </div>);
    } else {
      return (<div>
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        Page Not Found
      </div>);
    }
  }
}

export default Industries;
