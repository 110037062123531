import React, {Component} from 'react';
import './style.scss';
import {Link} from 'react-router-dom';
import {NavLink} from 'react-router-dom'

class Header extends Component {

  constructor() {
    super();
    this.state = {
      navclass: 'closed',
      navwrap: '',
      navstate: 'nav-down top'
    };
  }

  componentDidMount() {
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var header = document.getElementsByTagName('header');
    var navbarHeight = header[0].offsetHeight;

    //on user scroll set var to true
    window.onscroll = function changeNav() {
      didScroll = true;
    }
    //throttle the scroll check
    setInterval(function() {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250);

    function hasScrolled() { //logic to control the pop in/out nav.
      var header = document.getElementsByTagName('header');
      var st = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
      if (Math.abs(lastScrollTop - st) <= delta)
        return;

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > lastScrollTop && st > navbarHeight) {
        // Scroll Down
        header[0].classList.remove("nav-down");
        header[0].classList.add("nav-up");
      } else {
        // Scroll Up
        if (st + window.innerHeight < document.documentElement.offsetHeight || st <= 700) {
          header[0].classList.remove("nav-up");
          header[0].classList.add("nav-down");
        }
      }

      if (st <= 100) { //must be at least this many px down the page.
        header[0].classList.add("top");
      } else {
        header[0].classList.remove("top");
      }

      lastScrollTop = st;
    }
  }

  navMenuToggle() {
    var appWrapper = document.getElementById('app');
    if (this.state.navclass === 'closed') {
      this.setState({navclass: 'open'});
      appWrapper.classList.add("open");
      this.setState({navwrap: 'lock'}); //lock the nav on click to prevent double click
      setTimeout(() => {
        this.setState({navwrap: ''}); //reset nav lock
      }, 700);
    } else {
      this.setState({navclass: 'closed'});
      this.setState({navwrap: 'lock'}); //lock the nav on click to prevent double click
      setTimeout(() => {
        appWrapper.classList.remove("open");
        this.setState({navwrap: ''}); //reset nav lock
      }, 500);
    }
    //ScrollUp();
  }

  handleLogoClick() { //close the nav if you click the logo while it's open.
    var appWrapper = document.getElementById('app');
    if (this.state.navclass === 'closed') {
      //do a whole lot of nothing
    } else {
      this.setState({navclass: 'closed'});
      setTimeout(function() {
        appWrapper.classList.remove("open");
      }, 1100);
    }
    //ScrollUp();
  }
  componentDidUpdate() {
    window.onpopstate = (e) => { //on forward or back nav close the navigation menu.
      if (this.state.navclass === 'open') {
        this.navMenuToggle();
      } else {
        //do nothing
      }
    }
  }

  render() {
    return (<header className={this.state.navstate}>
      <div className={"nav-wraper"}>
        <Link to={'/'} className="logo" onClick={() => {this.handleLogoClick()}}></Link>
        <div className="mainNav">
          <Link to={'/solutions'} className="text-nav">Solutions</Link>
          <Link to={'/about'} className="text-nav">About</Link>
          {/* <Link to={'/partners'} className="text-nav">Partnership</Link> */}
          <Link to={'/contact'} className="text-nav">Contact</Link>
          <div className={'hamburger ' + this.state.navclass + ' ' + this.state.navwrap} onClick={() => {
              this.navMenuToggle()
            }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <nav className={'navigation ' + this.state.navclass}>
        <div className={"nav-wrap " + this.state.navwrap}>
          <div className="social-links">
            <span>Social</span>
              <a href="https://www.linkedin.com/company/gritdigitalhealth">Linkedin</a><br />
              <a href="https://fb.me/GritDigitalHealth">Facebook</a><br />
              <a href="https://www.instagram.com/gritdigitalhealth/">Instagram</a><br />
              <a href="https://twitter.com/grit_digital">Twitter</a>
          </div>
          <div className="large-nav">
            <ul>
              <li>
                <NavLink to={'/solutions'} exact activeClassName="is-active" onClick={() => {
                    this.navMenuToggle()
                  }}>Solutions</NavLink>
              </li>
              <li>
                <NavLink to={'/about'} activeClassName="is-active" onClick={() => {
                    this.navMenuToggle()
                  }}>About</NavLink>
              </li>
              {/*
              <li>
                <NavLink to={'/partners'} exact activeClassName="is-active" onClick={() => {
                    this.navMenuToggle()
                  }}>Partnership</NavLink>
              </li>
                */}
              <li>
                <NavLink to={'/contact'} activeClassName="is-active" onClick={() => {
                    this.navMenuToggle()
                  }}>Contact</NavLink>
              </li>
            </ul>
          </div>
          <div className="small-nav">
            <ul>
              <li>
                <NavLink to={'/ideas'} activeClassName="is-active" onClick={() => {
                    this.navMenuToggle()
                  }}>Newsroom</NavLink>
              </li>
              <li>
                <NavLink to={'/team'} text={'Team'} onClick={() => {
                    this.navMenuToggle()
                  }}>Our Team</NavLink>
              </li>
              <li>
                <NavLink to={'/speaking-engagements'} activeClassName="is-active" onClick={() => {
                    this.navMenuToggle()
                  }}>Speakers</NavLink>
              </li>
              {/*
              <li>
                <NavLink to={'/capabilities'} activeClassName="is-active" onClick={() => {
                    this.navMenuToggle()
                  }}>Capabilities</NavLink>
              </li>
              */}
              <li>
                <NavLink to={'/careers'} activeClassName="is-active" onClick={() => {
                    this.navMenuToggle()
                  }}>Careers</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    );
  }
}

export default Header;
