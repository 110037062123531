import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import './style.scss';

class TeamMember extends Component {

  constructor() {
    super();
    this.state = {
      matched: false,
      post: {}
    };
  }

  componentWillMount() {
    const post_name = this.props.match.params.team_member;
    const post = this.props.initData.team_members[post_name];
    if (post)
      this.setState({matched: true, post: post});
      //console.log('Team Member', post);
    }

  render() {
    if (this.state.matched) {
      return (<div id="team_member" className="pagewrap">
        <Helmet>
          <title>{this.state.post.meta_title.toString()}</title>
          <link rel="canonical" href={"https://www.gritdigitalhealth.com/team/" + this.state.post.post_name.toString()}/>
          <meta name="description" content={this.state.post.meta_description.toString()}/>
          <meta name="twitter:card" value="summary"/>
          <meta property="og:title" content={this.state.post.meta_title.toString()}/>
          <meta property="og:type" content="article"/>
          <meta property="og:url" content={"https://www.gritdigitalhealth.com/team/" + this.state.post.post_name.toString()}/>
          <meta property="og:image" content={this.state.post.social_image}/>
          <meta property="og:description" content={this.state.post.meta_description.toString()}/>
        </Helmet>
        <div className={'content-wrap'}>
          <Link to={'/team'} className="crumb-link">
            <span>Team</span>
          </Link><br/>
          <div className="member-image">
            <img className="desktop-img" src={this.state.post.photo.sizes.medium} alt={this.state.post.photo.alt}/>
            <img className="mobile-img" src={this.state.post.photo.sizes.medium} alt={this.state.post.photo.alt}/>
          </div>
          <div className="type-wrap">
            <h1>
              {this.state.post.post_title}
            </h1>
            <h2>{this.state.post.team_title}</h2>
          </div>
        </div>
        <div className="body-wrap">
          <div className={'content-wrap'}>
            <div className="type-wrap">
              <p className="body">{this.state.post.description}</p>
            </div>
          </div>
        </div>
      </div>);
    } else {
      return (<div id="team_member">
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        Page Not Found
      </div>);
    }
  }
}

export default TeamMember;
