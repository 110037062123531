import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import './style.scss';

class Team extends Component {

  constructor() {
    super();
    this.state = {};
  }

  getTeamMembers() {
    const team_data = this.props.initData.team_members;

    //Case Studies
    var team_members_sorted = [];
    for (var key in team_data) {
      team_members_sorted.push(team_data[key]);
    }
    team_members_sorted.sort(function(a, b) {
      return a.menu_order - b.menu_order
    });

    var team_members = [];
    team_members_sorted.forEach(function(team_member, key) {
      team_members.push(<Link className={"team_member hidden " + team_member.large_or_small} key={key} to={'/' + this.props.initData.team.post_name + '/' + team_member.post_name}>
        <div className="main-img">
          <img className="desktop-img" src={team_member.photo.sizes.medium} alt={team_member.photo.alt}/>
          <img className="mobile-img" src={team_member.photo.sizes.medium_large} alt={team_member.photo.alt}/>
        </div>
        <h2>{team_member.post_title}</h2>
        <h4>{team_member.team_title}</h4>
      </Link>);
    }, this);
    return team_members;
  }

  render() {
    return (<div id="team" className="pagewrap">
      <Helmet>
        <title>{this.props.initData.team.meta_title.toString()}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/team"/>
        <meta name="description" content={this.props.initData.team.meta_description.toString()}/>
        <meta name="twitter:card" value="summary"/>
        <meta property="og:title" content={this.props.initData.team.meta_title.toString()}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content="https://www.gritdigitalhealth.com/team"/>
        <meta property="og:image" content={this.props.initData.team.social_image}/>
        <meta property="og:description" content={this.props.initData.team.meta_description.toString()}/>
      </Helmet>
      <div className={'content-wrap'}>
        <Link to={'/about'} className="crumb-link">
          <span>About
          </span>
        </Link><br/>
        <h1>
          {this.props.initData.team.headline}
        </h1>
        {/* <div className={'text-link'} onClick={this.handleClick.bind(this)} >skip to Leadership Team</div> */}
        <br/>
      </div>
      <div className={'content-wrap'}>
        <div className='intro-copy'>
          <h3>{this.props.initData.team.intro_copy}</h3>
        </div>
      </div>
      <div id="team_members">
        <div className={'content-wrap'}>
          {this.getTeamMembers()}
        </div>
      </div>
    </div>);
  }
}

export default Team;
