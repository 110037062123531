import React from "react";



export const Footer = ({ logo, href }) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer">
            <footer>
                <div className="container">
                    <p>From your friends at Grit Digital Health. And, we make more than great recipes.</p> <p className="bold"> Visit </p>
                    <img src={logo} height='24px' width='auto' alt="grit digital health" />
                </div>
            </footer>
        </a>
    )
}