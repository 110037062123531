import React from "react";
import { Link } from "react-router-dom";
import arrowBlack from "../Tips/img/arrow-black.svg";
import arrowWhite from "./img/arrow-white.svg";

export const TipCard = ({ tip }) => {
  const postLink = `/tips/${tip.post_name}`;

  return (
    <Link className={`tip-card card ${tip.category}`} to={postLink}>
      <div className="card-top">
        <div className="image">
          <img src={tip.image.sizes.large} alt={tip.post_title} />
        </div>
        <div className="card-divider">
          <svg
            width="374"
            height="23"
            viewBox="0 0 374 23"
            preserveAspectRatio="none"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M374 23H0.5V0C155.078 24.1244 313.574 10.0519 374 0V23Z"
              fill="#F1EEE8"
            />
          </svg>
        </div>
      </div>

      <div className="card-body">
        <div className="card-title">{tip.post_title}</div>
        <button className="cta black-outline arrow left-align standard">
          Read More
          <div className="arrow">
            <img
              src={arrowBlack}
              className="arrow-icon black"
              alt="arrow"
              height="13px"
              width="17px"
            />
            <img
              src={arrowWhite}
              className="arrow-icon white"
              alt="arrow"
              height="13px"
              width="17px"
            />
          </div>
        </button>
      </div>
    </Link>
  );
};
