import { Helmet } from 'react-helmet'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { TipCard } from '../Tips/card'
import { Footer } from '../Tips/footer'
import gritLogo from '../Tips/img/grit-logo-white.svg'
import { Share } from '../Tips/share'
import '../Tips/common.scss'
import './style.scss'
import stars from '../Tips/img/stars-black.svg'
import { CtaArrow } from '../Tips/ctaArrow'

const Tip = props => {
  let data = Object.entries(props.initData.tips)
  let tempItem = data.find(o => o[1].post_name === props.match.params.title)

  const item = tempItem[1]

  if (!item) {
    return <h1>Item Not Found</h1>
  }

  const category = item['category']
  const shuffle = arr => {
    return arr.sort(() => Math.random() - 0.5)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const moreTips = useMemo(() => {
    return shuffle(data)
      .filter(o => o[1].post_name !== item.post_name)
      .filter(o => o[1]['category'] === category)
      .slice(0, 3)
      .map((item, index) => {
        return <TipCard key={index} tip={item[1]} />
      })
  }, [category, data, item.post_name])

  const env = process.env.ENVIRONMENT ? process.env.ENVIRONMENT : 'staging'

  const siteRoot =
    env === 'staging'
      ? 'https://gritdev.netlify.app'
      : 'https://gritdigitalhealth.com'

  let optionalCTA = null

  if (typeof item['optional_cta'] === 'object') {
    const title = item['optional_cta']['title']
    const url = item['optional_cta']['url']
    if (url.includes(siteRoot)) {
      optionalCTA = (
        <Link
          className="cta black-outline"
          style={{ display: 'inline-flex' }}
          to={url.replace(siteRoot, '')}
          target="_blank"
          rel="nofollow noreferrer"
        >
          {title}
          <CtaArrow />
        </Link>
      )
    } else {
      optionalCTA = (
        <a
          className="cta black-outline"
          href={url}
          style={{ display: 'inline-flex' }}
          target="_blank"
          rel="nofollow noreferrer"
        >
          {title}
          <CtaArrow />
        </a>
      )
    }
  }

  return (
    <div id="tip">
      <Helmet>
        <title>{`Grit Holiday Tips | ${item.post_title.trim()}`}</title>
        <link
          rel="canonical"
          href={`${siteRoot}/tips/${props.match.params.title}`}
        />
        <meta
          name="description"
          content={props.initData.tips_page.meta_description}
        />
        <meta name="twitter:card" value="summary" />
        <meta
          property="og:title"
          content={`Grit Holiday Tips | ${item.post_title.trim()}`}
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`${siteRoot}/tips/${props.match.params.title}`}
        />
        <meta
          property="og:image"
          content={props.initData.tips_page.social_image}
        />
        <meta
          property="og:description"
          content={props.initData.tips_page.meta_description}
        />
      </Helmet>

      <header>
        <div className="container">
          <div className="navigation">
            <Share
              title="Share Website"
              color="black"
              href={`http://www.facebook.com/sharer/sharer.php?u=https://${
                env === 'staging'
                  ? 'gritdev.netlify.app'
                  : 'gritdigitalhealth.com'
              }/tips/${props.match.params.title}`}
            />
          </div>
          <div className="flex stars-wrap relative">
            <img
              className="stars"
              height="38px"
              width="47px"
              alt="stars"
              src={stars}
            />

            <div className="flourish top left"></div>
            <div className="flourish top right"></div>
          </div>
          <Link to="/tips">
            <h1>{item.post_title}</h1>
          </Link>
        </div>
      </header>

      <main>
        <section className="tip-breakdown">
          <div className="container">
            <Link
              to="/tips"
              style={{
                textDecoration: 'underline',
                marginBottom: '22px',
                color: '#333'
              }}
            >
              Back to tips
            </Link>
          </div>
          <div className="container">
            <div className="left">
              <div className="img-wrap">
                <img src={item.image.sizes.large} alt={item.post_title} />
              </div>
            </div>

            <div className="right">
              <div
                dangerouslySetInnerHTML={{
                  __html: item.tip
                }}
              ></div>
              {optionalCTA}
            </div>
          </div>
        </section>
      </main>
      <div className="container">
        <div className="flex" style={{ height: '30px', position: 'relative' }}>
          <div className="flourish bottom left"></div>
          <div className="flourish bottom right"></div>
        </div>
      </div>

      <section className="more-tips">
        <div className="container">
          <div className="title">
            <h2>More Tips from Our Team</h2>
          </div>

          <div className="cards">{moreTips}</div>

          <div className="button-wrap">
            <div className="container">
              <Link
                to={`/tips?cat=${item['category']}`}
                className="button active view-more"
              >
                View More Tips
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer href="/" logo={gritLogo} />
    </div>
  )
}

export default Tip
