import { Helmet } from "react-helmet";
import React, { useState, useMemo } from "react";
import { data } from "./data";
import { Share } from "./share";
import gritLogo from './img/grit-logo.svg'
import gritHeader from '../RecipesIndex/img/grit-digital-header.svg'
import { RecipeCard } from "./card";
import { Footer } from "./footer";
import qs from 'qs'
import starsGroupOne from './img/stars-group-1.png'
import starsGroupTwo from './img/stars-group-2.png'
import starsGroupThree from './img/stars-group-3.png'
import tinyStar from './img/tiny-star.png'
import bigStar from './img/big-star.png'
import "./common.scss";
import "./style.scss";

const RecipesIndex = (props) => {
  const acc = 12;
  const shuffle = (arr) => {
    return arr.sort(() => Math.random() - 0.5)
  }

  const shuffledData = useMemo(() => {
    return shuffle(data);
  }, [])

  const env = process.env.ENVIRONMENT
    ? process.env.ENVIRONMENT
    : 'staging';

  const filterParam = qs.parse(props.location.search, { ignoreQueryPrefix: true }).cat || "all-recipes"

  const catMap = {
    'all-recipes': "Recipe",
    'drink': "Drink",
    'dish': "Dish",
    'dessert': "Dessert"
  }

  const filterMap = {
    'all-recipes': "Recipes",
    'drink': "Drinks",
    'dish': "Dishes",
    'dessert': "Desserts",
    'drinks': "Drinks",
  }

  const fitlerToId = {
    "Recipes" : 'all-recipes',
    "Drinks" : 'drink',
    "Dishes" : "dish",
    "Desserts" : 'dessert'
  }


  const [idx, setIdx] = useState(acc);
  const [filterTitle, setFilterTitle] = useState(filterMap[filterParam]);
 
  const [hide, setHide] = useState(false);
  const [filterButtonsArr, setFilterButtonsArr] = useState([
    { id: "all-recipes", title: "All Recipes", active: filterParam === 'all-recipes' },
    { id: "drink", title: "Drinks", active: filterParam === 'drink' },
    { id: "dish", title: "Dishes", active: filterParam === 'dish' },
    { id: "dessert", title: "Desserts", active: filterParam === 'dessert' },
  ]);

  const renderCards = (cards) => {
    return (
      cards.map((item, index) => {
        return <RecipeCard
          key={index}
          title={item["Recipe Title"].trim()}
          time={item["Estimated Time to Complete"]}
          category={item["Recipe Category"]}
          src={item["src"]}
        />
      })
    )
  }

  const [output, setOutput] = useState(
    filterParam === 'all-recipes' ?
     renderCards(shuffledData.slice(0, idx)) : 
      renderCards(shuffledData.filter(o => o["Recipe Category"] === catMap[filterParam]).slice(0, idx))
  );

  const FilterButton = ({ title, id, eventHandler, active }) => {
    return <button onClick={eventHandler} className={`box ${active ? 'active' : 'not-active'}`} id={id} >{title}</button>
  }

  const handleClick = (e) => {
    const activeButton = e.currentTarget.id;
    setHide(true);
    setFilterTitle(filterMap[activeButton])
    setFilterButtonsArr([
      { id: "all-recipes", title: "All Recipes", active: activeButton === 'all-recipes' },
      { id: "drink", title: "Drinks", active: activeButton === 'drink' },
      { id: "dish", title: "Dishes", active: activeButton === 'dish' },
      { id: "dessert", title: "Desserts", active: activeButton === 'dessert' }
    ])

    setTimeout(() => {
      if (activeButton === "all-recipes") {     
        setOutput(
          renderCards(
            shuffledData.slice(0, idx)
          )
        )
      } else {
        setOutput(
          renderCards(
            shuffledData.filter(o => o["Recipe Category"] === catMap[activeButton]).slice(0, idx)
          )
        )
      }
      setHide(false)
    }, 300)
  }

  const filterButtons = filterButtonsArr.map((item, index) => {
    return <FilterButton id={item.id} key={index} active={item.active} title={item.title} eventHandler={handleClick} />
  })

  const handleMore = () => {
    const activeFilter = fitlerToId[filterTitle]

    if (activeFilter === 'all-recipes'){
      setOutput(
        renderCards(shuffledData.slice(0, idx + acc)) 
      )
    } else {
      setOutput(
        renderCards(shuffledData.filter(o => o["Recipe Category"] === catMap[activeFilter]).slice(0, idx + acc))
      )
    }
    setIdx(idx + acc)
  }

  const availableRecipesInCategory  = {
    'all-recipes': data.length,
    'drink': shuffledData.filter(o => o["Recipe Category"] === "Drink").length,
    'dish': shuffledData.filter(o => o["Recipe Category"] === "Dish").length,
    'dessert': shuffledData.filter(o => o["Recipe Category"] === "Dessert").length,
  }

  return (
    <div id='recipes'>
      <Helmet>
        <title>{props.initData.recipes.meta_title}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/recipes" />
        <meta name="description" content={props.initData.recipes.meta_description} />
        <meta name="twitter:card" value="summary" />
        <meta property="og:title" content={props.initData.recipes.meta_title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={"https://www.gritdigitalhealth.com/recipes"} />
        <meta property="og:image" content={props.initData.recipes.social_image} />
        <meta property="og:description" content={props.initData.recipes.meta_description} />
      </Helmet>
      <div className="page-texture"></div>

      <header>
        <div className="flourish top left"></div>
        <div className="flourish top right"></div>
        <div className="flourish bottom left"></div>
        <div className="flourish bottom right"></div>
        <div className="rect"></div>

        <div className="container">
          <div className="navigation">
            <Share title="Share Website" href={`http://www.facebook.com/sharer/sharer.php?u=https://${env === 'staging' ? 'gritdev.netlify.app' : 'gritdigitalhealth.com'}/recipes`} />
          </div>
          <h1><img src={gritHeader} height='170px' alt="grit holiday recipes" /></h1>
          <div className="subhead">
            We are back in the season of giving, and nothing gives more joy this time of year than having an excuse to indulge in your favorite foods and drinks.
          </div>

        </div>

     
      </header>

      <main>
        <div className="container">
          <div className="stars one" style={{ top: '53vh', left:"-4%"}}>
            <img src={starsGroupOne} alt="" height="104px" width='72px' />
          </div>
          <div className="stars two" style={{ bottom: '30vh', right:"-5%"}}>
            <img src={starsGroupTwo} alt="" height="95px" width='115px' />
          </div>
          <div className="stars three" style={{ bottom: '10vh', left:"-6%"}}>
            <img src={starsGroupThree} alt="" height="110px" width='83px' />
          </div>
          <div className="stars four" style={{ top: '70vh', left:"65%"}}>
            <img src={tinyStar} alt="" height="22px" width='22px' />
          </div>
          <div className="stars five" style={{ top: '10vh', left:"31%"}}>
            <img src={bigStar} alt="" height="48px" width='48px' />
          </div>
          <div className="stars six" style={{ top: '16vh', right:"-5%"}}>
            <img src={bigStar} alt="" height="48px" width='48px' />
          </div>

          <div className="filter">
            {filterButtons}
          </div>
          <div className={`recipes-index ${hide ? "hide" : "show"}`}>
            {output}
          </div>
        </div>
      </main>

      <section className="bottom"  >
        <div className="container">
          {idx < availableRecipesInCategory[fitlerToId[filterTitle]] ?
            <div className="fade"></div> : ""}

          {idx < availableRecipesInCategory[fitlerToId[filterTitle]] ?
            <button className='active view-more' onClick={handleMore}>Load More {filterTitle}</button> : ""}
        </div>
   
      </section>

      <Footer href="https://gritdigitalhealth.com" logo={gritLogo} />

    </div>
  )
}

export default RecipesIndex;