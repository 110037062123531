import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import IdeasBlock from '../IdeasBlock';
import FooterCallout from '../FooterCallout';
import './style.scss';

class About extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageData: this.props.initData.about,
    };
  }

  render() {
    return (
      <div id="about" className="pagewrap">
      <Helmet>
        <title>{this.state.pageData.meta_title}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/about"/>
        <meta name="description" content={this.state.pageData.meta_description}/>
        <meta name="twitter:card" value="summary"/>
        <meta property="og:title" content={this.state.pageData.meta_title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content="https://www.gritdigitalhealth.com/about"/>
        <meta property="og:image" content={this.state.pageData.social_image}/>
        <meta property="og:description" content={this.state.pageData.meta_description}/>
      </Helmet>
      <div className="copy-block">
        <div className="copy-wrap intro">
          <div className="left hidden">
            <h1>{this.state.pageData.headline}</h1>
            <h3>{this.state.pageData.subhead}</h3>
          </div>
          <div className="right hidden">
            <p dangerouslySetInnerHTML={{
                __html: this.state.pageData.intro
              }}/>
          </div>
        </div>
      </div>
      <div className="capabilities">
        <img src={'/img/GDH-Infographic.png'} alt="capabilities"/>
      </div>
      <div className="team hidden">
        <div className="image">
          <img className="desktop" src={this.state.pageData.team_section.image_asset.sizes.large} alt={this.state.pageData.team_section.image_asset.title}/>
          <img className="mobile" src={this.state.pageData.team_section.image_asset.sizes.medium} alt={this.state.pageData.team_section.image_asset.title}/>
        </div>
        <div className="content">
          <div className="type">
            <h3>{this.state.pageData.team_section.headline}</h3>
            <p>{this.state.pageData.team_section.body_copy}</p>
          </div>
        <Link to={"/team/"} className="button">{this.state.pageData.team_section.button_text}</Link>
        </div>
      </div>
      <div className="speakers hidden">
        <div className="image mobile">
          <img className="mobile" src={this.state.pageData.speaking_engagements.image_asset.sizes.medium} alt={this.state.pageData.speaking_engagements.image_asset.title}/>
        </div>
        <div className="content">
          <div className="type">
            <h3>{this.state.pageData.speaking_engagements.headline}</h3>
            <p>{this.state.pageData.speaking_engagements.body_copy}</p>
          </div>
        <Link to={"/speaking-engagements/"} className="button">{this.state.pageData.speaking_engagements.button_text}</Link>
        </div>
        <div className="image desktop">
          <img className="desktop" src={this.state.pageData.speaking_engagements.image_asset.sizes.large} alt={this.state.pageData.speaking_engagements.image_asset.title}/>
        </div>
      </div>
      <IdeasBlock initData={this.state.pageData} />
      <FooterCallout calloutData={this.props.initData.about.footer_callout} />
    </div>);
  }
}

export default About;
