import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import './style.scss';

class News extends Component {

  constructor() {
    super();
    this.state = {
      page: 1,
      posts: [],
      categories: [],
      catID: 0,
      totalPages: 0,
      lastPage: false,
      totalPosts: 0,
      totalCats: 0,
      postAppearance: 'visible',
      headers: [],
      spanWidth: '10px',
      spanPosition: '-20px'
    };
  }

  componentDidMount() {
    this.fetchPosts(1, 0); //Fetch initial posts
    this.fetchCats();
  }

  prevPosts = e => {
    e.preventDefault();
    this.fadeOutAnimation();
    if (this.state.page > 1)
      this.fetchPosts(this.state.page - 1, this.state.catID);
    }
  nextPosts = e => {
    e.preventDefault();
    this.fadeOutAnimation();
    this.fetchPosts(this.state.page + 1, this.state.catID);
  }

  handleClick = (page, catID) => {
    this.fadeOutAnimation();
    this.fetchPosts(page, catID);
    //console.log(catID);
  }

  handleSpan() {
    if (this.state.catID === 72) {
      this.setState({spanWidth: "10px"});
      this.setState({spanPosition: "90px"});
    } else if (this.state.catID === 71) {
      this.setState({spanWidth: "10px"});
      this.setState({spanPosition: "235px"});
    } else {
      this.setState({spanWidth: "10px"});
      this.setState({spanPosition: "-20px"});
    }
  }

  fadeOutAnimation() {
    this.setState({postAppearance: "hidden"});
    var element = document.getElementById("allPosts");
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scroll({top: y, behavior: 'smooth'});
  }

  fadeInAnimation() {
    this.handleSpan();
    this.nextCheck();
    this.setState({postAppearance: "visible"});
  }


  fetchPosts(page, catID) {
    if (catID !== 0) {
      fetch('https://test-grit-cms.pantheonsite.io/wp-json/wp/v2/posts?categories=' + catID + '&per_page=3&page=' + page).then(response => {
        var totalPosts = response.headers.get('X-WP-Total');
        this.setState({totalPosts: totalPosts});
        return response.json()
      }).then(posts => {
        this.setState({page: page, posts: posts, catID: catID});
        this.fadeInAnimation();
      });
    } else {
      fetch('https://test-grit-cms.pantheonsite.io/wp-json/wp/v2/posts?per_page=3&page=' + page).then(response => {
        var totalPosts = response.headers.get('X-WP-Total');
        this.setState({totalPosts: totalPosts});
        return response.json()
      }).then(posts => {
        this.setState({page: page, posts: posts, catID: catID});
        this.fadeInAnimation();
      });
    }
  }

  getAllPosts() {
    const post_data = this.state.posts;
    //console.log(post_data);
    var allPosts = [];
    for (var key in post_data) {
      allPosts.push(<Link className={"post-card " + post_data[key].acf.isFeatured} key={key} to={'/ideas/' + post_data[key].slug}>
        <div className={post_data[key].acf.featured_image
            ? "image-wrap"
            : "hide"}>
          <img src={post_data[key].acf.featured_image
              ? (post_data[key].acf.featured_image.sizes.medium_large)
              : (undefined)} alt={post_data[key].title.rendered}
              />
        </div>
        <div className={"content " + post_data[key].categories[0]}>
          <h3 dangerouslySetInnerHTML={{
              __html: post_data[key].title.rendered
            }}/>
          <p dangerouslySetInnerHTML={{
              __html: post_data[key].excerpt.rendered.replace(/<\/?[^>]+>/gi, '')
            }}/>
          <div text={""} className="case-study-link"/>
        </div>
      </Link>);
    }
    //console.log(this.state.posts);
    return allPosts;
  }
  nextCheck() {
    var totalPosts = this.state.totalPosts;
    var pageNumber = Math.ceil(totalPosts / 6);
    if (pageNumber === this.state.page) {
      this.setState({lastPage: true});
    } else {
      this.setState({lastPage: false});
    }
  }

  getPagination() {
    var totalPosts = this.state.totalPosts;
    var pageNumber = Math.ceil(totalPosts / 3);
    var pagination = [];
    for (let i = 0; i < pageNumber; i++) {
      pagination.push(<a key={i} className={this.state.page} onClick={(e) => this.handleClick(i + 1, this.state.catID)}>{i + 1}</a>)
    }
    return pagination;
  }

  fetchCats() {
    fetch('https://test-grit-cms.pantheonsite.io/wp-json/wp/v2/categories').then(response => {
      //var totalCats = response.headers.get('X-WP-Total');
      //this.setState({totalCats: totalCats});
      return response.json()
    }).then(categories => {
      this.setState({categories: categories});
    });
  }

  render() {
    return (<div id="news">
      <Helmet>
        <title>{this.props.initData.news.meta_title}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/ideas"/>
        <meta name="description" content={this.props.initData.news.meta_description}/>
        <meta name="twitter:card" value="summary"/>
        <meta property="og:title" content={this.props.initData.news.meta_title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content="https://www.gritdigitalhealth.com/ideas"/>
        <meta property="og:image" content={this.props.initData.news.social_image}/>
        <meta property="og:description" content={this.props.initData.news.meta_description}/>
      </Helmet>
      <div className={'content-wrap'}>
        <h1 className="styled">
          {this.props.initData.news.headline}
        </h1>
        {/* <Button path="#hire-us" text={'skip to Hire Us'} className={'text-link'} /> */}
      </div>
      <div className="articles">
        <div className={"all-posts"} id="allPosts">
          <div className="filter-wrap">
            <h2>{this.props.initData.news.postsTitle}</h2>
            <div className="filter-innerwrap">
              <div className={this.state.catID === 0
                  ? 'filterLink active'
                  : 'filterLink'} onClick={() => {
                  this.handleClick(1, 0)
                }}>See All</div>
              <div className={this.state.catID === 72
                  ? 'filterLink active'
                  : 'filterLink'} onClick={() => {
                  this.handleClick(1, 72)
                }}>Staff News</div>
              <div className={this.state.catID === 71
                  ? 'filterLink active'
                  : 'filterLink'} onClick={() => {
                  this.handleClick(1, 71)
                }}>Product News</div>
              <span className="line" style={{
                  'width' : this.state.spanWidth,
                  'left' : this.state.spanPosition
                }}/>
            </div>
          </div>
          <div className={"content-wrap " + this.state.postAppearance}>
            {this.getAllPosts()}
          </div>
        </div>
        {
          this.state.totalPosts > 3
            ? <div className="post-nav">
                <div className="content-wrap">
                  <a href="" className={"prev " + this.state.page} onClick={this.prevPosts}>Previous Page</a>
                  <div className={"count"}>
                    {this.getPagination()}
                  </div>
                  <a href="" className={this.state.lastPage
                      ? "next last " + this.state.page
                      : "next " + this.state.page} onClick={this.nextPosts}>Next Page</a>
                </div>
                <br/>
              </div>
            : ''
        }
      </div>
    </div>);
  }
}

export default News;
