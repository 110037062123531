import React, { useState } from "react";
import share from "./img/share-icon.svg";
import shareWhite from "./img/share-icon-white.svg";
import facebook from "./img/facebook.svg";
import mail from "./img/mail-icon.svg";

export const Share = ({ color, title, href }) => {
  const [shareActive, setShareActive] = useState(false);

  const handleShareLeave = () => {
    setShareActive(false);
  };

  const handleClick = () => {
    setShareActive(!shareActive);
  };
  return (
    <div className="share-wrap" onMouseLeave={handleShareLeave}>
      <button
        className={`share cta ${color}-outline standard has-icon`}
        onClick={handleClick}
      >
        {title || "Share"}

        <div className="icon-wrap">
          <img
            src={share}
            className="black"
            height="20px"
            width="20px"
            alt="share"
          />
          <img
            src={shareWhite}
            className="white"
            height="20px"
            width="20px"
            alt="share"
          />
        </div>
      </button>
      <div
        className={`share-link-wrap ${shareActive ? "active" : "not-active"}`}
      >
        <a
          className="facebook"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook} height="30px" alt="share" />
        </a>
        <a
          className="mail"
          href={`mailto:?subject=Holiday Well-Being Tips&body=https://gritdigitalhealth.com/tips`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={mail} width="16px" alt="share" />
        </a>
      </div>
    </div>
  );
};
