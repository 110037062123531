export const gratitudeMessages = [
  {
    "image": "cactus.png",
    "keep": "YES",
    "copy": "Think of a time you were swept away in nature. What are some ways you can give back to the environment this season?"
  },
  {
    "image": "teacups.png",
    "keep": "YES",
    "copy": "Comforts like a warm bed or a roof over our head - are often taken for granted. Reflect on the magnitude of the essentials."
  },
  {
    "image": "dog.png",
    "keep": "YES",
    "copy": "Think of a furry companion (your own or someone else's) and how it has made you happy, and imagine how they think of you, too. "
  },
  {
    "image": "cookie.png",
    "keep": "YES",
    "copy": "Think of someone you know who works tirelessly to make other's lives better. Reach out and thank them for their endeavors."
  },
  {
    "image": "calendar.png",
    "keep": "YES",
    "copy": "It's been a hard year for most. What have you had the opportunity to learn about yourself in 2020?"
  },
  {
    "image": "thinking-ape.png",
    "keep": "YES",
    "copy": "Take a deep breath. What do you see, hear, smell, taste and feel? Be present in your surroundings for a moment. "
  },
  {
    "image": "noodles.png",
    "keep": "YES",
    "copy": "What's your favorite scent? Think about how your sniffer can transplant you to another time and place. Thank you, nose. "
  },
  {
    "image": "otters.png",
    "keep": "YES",
    "copy": "Being social has been difficult this year. Think of a friend or family member who brought some light to a dark time, and thank them. "
  },
  {
    "image": "soccer.png",
    "keep": "YES",
    "copy": "Your mission today: every time you think of a memory with a loved one, no matter how small - send a quick message and let them know. "
  },
  {
    "image": "lightbulb.png",
    "keep": "YES",
    "copy": "One of the most important things you can do is be yourself. What are some ways you've grown this year that have made you proud?"
  },
  {
    "image": "lightbulb.png",
    "keep": "YES",
    "copy": "Give yourself a good (and much deserved) pep talk! Take some time to be proud of yourself. You're crushing it!"
  },
  {
    "image": "paper-plane.png",
    "keep": "YES",
    "copy": "Write (and send!) a snail mail letter of gratitude to a coworker, friend or family member. "
  },
  {
    "image": "skateboard.png",
    "keep": "YES",
    "copy": "Next time you brush your teeth, remember there was once a time when dentists didn't exist!"
  },
  {
    "image": "thank-you.png",
    "keep": "YES",
    "copy": "Mentors always leave an indelible mark. Send a note of thanks to a mentor who helped improve your outlook."
  },
  {
    "image": "quokka.png",
    "keep": "YES",
    "copy": "Give your plants a little love - freshen up their water, remove any dead leaves. Smile at them and speak kindly. They notice!"
  },
  {
    "image": "beavers.png",
    "keep": "YES",
    "copy": "Look around! How have you cultivated your living space and how has that helped you through this unique year?"
  },
  {
    "image": "fish-school.png",
    "keep": "YES",
    "copy": "Failures, while painful, often provide learning experiences. What are some ways you're thankful for your failures and how have they helped you grow?"
  },
  {
    "image": "facetime.png",
    "keep": "YES",
    "copy": "What technology has helped you stay close to others this year? Give it a reboot because honestly you probably don't do that enough."
  },
  {
    "image": "signpost.png",
    "keep": "YES",
    "copy": "Think of a piece of critical feedback you received this year that actually helped you improve.  Appreciate the growth!"
  },
  {
    "image": "ant.png",
    "keep": "YES",
    "copy": "In what ways were your resilient in the face of uncertainty this year? Did anyone else around show resilience in a way that inspired you? Thank them, and yourself!"
  },
  {
    "image": "backpack.png",
    "keep": "YES",
    "copy": "Think back - has a teacher ever taken the time to invest in you and encourage you? Consider the ripple effect this has had on your life, and thank them if you can. "
  },
  {
    "image": "gift.png",
    "keep": "YES",
    "copy": "Think about your neighbors and the people you smile and say hello to daily, and how their lives are just as complex and layered as yours. If you don't know their names, it's never too late to ask! "
  },
  {
    "image": "dj-iguana.png",
    "keep": "YES",
    "copy": "Singing in the shower, dancing in the kitchen - small acts of joy make a huge difference. Let loose today and appreciate your goofy side. "
  },
  {
    "image": "snails.png",
    "keep": "YES",
    "copy": "Think of the first job that you ever had. What did it teach you about yourself?"
  },
  {
    "image": "phone-on-phone.png",
    "keep": "YES",
    "copy": "Find a funny GIF and send it to 5 friends who will appreciate it. Ask how they are, while you're at it!"
  },
  {
    "image": "snail-trivia.png",
    "keep": "YES",
    "copy": "Track down some old photos and send them to the people who share those memories with you."
  },
  {
    "image": "bulldogs.png",
    "keep": "YES",
    "copy": "Think of a person you had an unexpected but memorable experience with. Reach out to them and let them know what it meant to you!"
  },
  {
    "image": "thank-you.png",
    "keep": "YES",
    "copy": "Whether you're in a grocery store, bus, coffee shop, or restaurant, take the time to offer a very sincere thank you to the individuals who are working there."
  },
  {
    "image": "head-in-clouds.png",
    "keep": "YES",
    "copy": "Did anyone ever recommend a book or movie to you that ended up changing your life? Let them know how much that has meant to you."
  },
  {
    "image": "tin-can-convo.png",
    "keep": "YES",
    "copy": "Give the gift of time - take a real or virtual walk with a friend or family member and stay in the moment with them."
  },
  {
    "image": "wild-things-alpaca.png",
    "keep": "YES",
    "copy": "Think about the last time you did something truly selfless for someone and it how it changed your perspective."
  },
  {
    "image": "frogs.png",
    "keep": "YES",
    "copy": "What is the best day that you can remember having? Think about how the experience of that day made you feel."
  },
  {
    "image": "calendar.png",
    "keep": "YES",
    "copy": "Has anyone ever written you a note of gratitude? Think of how that made you feel and pay it forward to someone else."
  },
  {
    "image": "snakeheart.png",
    "keep": "YES",
    "copy": "Think about the last time someone gave you a small token of appreciation. How did it make you feel and how can you pass that along?"

  },
  {
    "image": "dino-bird.png",
    "keep": "YES",
    "copy": "What is the best advice you've ever received? Who in your life could use that same advice today?"
  },
  {
    "image": "handstand.png",
    "keep": "YES",
    "copy": "Look for the little wins. Think of a small victory you had this week and how it uplifted you."
  },
  {
    "image": "cracked-up-egg.png",
    "keep": "YES",
    "copy": "When was the last time that you made someone laugh? When is the last time YOU were overcome with the giggles? Remember, it's the best medicine. "
  },
  {
    "image": "coyotes.png",
    "keep": "YES",
    "copy": "Sunsets happen every day, yet are still magical. Reflect on how everyday moments in your life can be special, too. "
  },
  {
    "image": "worm-study-group.png",
    "keep": "YES",
    "copy": "Do you have a co-worker or peer that supports you both professionally and personally? Keep cheering each other on as whole people."
  },
  {
    "image": "turtles-hula.png",
    "keep": "YES",
    "copy": "Indulge in an inside joke that you have with family, friends or coworkers. Consider how this kind of closeness has brought you recurring joy. "
  },
  {
    "image": "badger.png",
    "keep": "YES",
    "copy": "What is a routine you developed during this unique year? How did it help you find normalcy in the midst of a lot of uncertainty?"
  },
  {
    "image": "talk-tree.png",
    "keep": "YES",
    "copy": "Creativity is a great way to counteract adversity. What are some ways that your creativity (or someone else's) has helped you through this year?"
  },
  {
    "image": "wild-thing-raccoon-question.png",
    "keep": "YES",
    "copy": "This year, we collectively wore more comfortable clothes than ever before. Thank your PJs (and maybe wash them)"
  },
  {
    "image": "owl.png",
    "keep": "YES",
    "copy": "Hunt down a piece of good news on the internet today and really sink into it."
  },
  {
    "image": "llamas.png",
    "keep": "YES",
    "copy": "Remember about a time you had a random but kind interaction with a stranger. Imagine where they are now."
  },
  {
    "image": "bubbling-pot.png",
    "keep": "YES",
    "copy": "What is the best food anyone has ever made for you? Thank them, if you can."
  },
  {
    "image": "donuts.png",
    "keep": "YES",
    "copy": "Remember a time when you served as a mentor for someone else - and where they are now."
  },
  {
    "image": "cup-of-tea.png",
    "keep": "YES",
    "copy": "Be grateful for the breakfast and coffee you had this morning"
  },
  {
    "image": "parrot-trex.png",
    "keep": "YES",
    "copy": "Think about your favorite artist, musician or author and how they helped you see the world differently"
  },
  {
    "image": "pizza.png",
    "keep": "YES",
    "copy": "Think of someone who shared a recipe that has become a go-to for you. Thank them!"
  }
]
