import React from "react";
import arrowBlack from "../Tips/img/arrow-black.svg";
import arrowWhite from "./img/arrow-white.svg";

export const CtaArrow = () => {
  return (
    <div className="arrow">
      <img
        src={arrowBlack}
        className="arrow-icon black"
        alt="arrow"
        height="13px"
        width="17px"
      />
      <img
        src={arrowWhite}
        className="arrow-icon white"
        alt="arrow"
        height="13px"
        width="17px"
      />
    </div>
  );
};
