import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import Slider from '../Slider';
import RelatedSlider from '../RelatedProjects';
import VimeoPlayer from '../VimeoPlayer';
import IdeasBlock from '../IdeasBlock';
import './style.scss';

class CaseStudy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      matched: false,
      post: {},
      currentUrl: window.location.href,
      caseType: this.props.caseType
    };
  };

  componentWillMount() {
    var post_name = '';
    var post = '';
    if (this.state.caseType === 'products') { //if it's a product
      post_name = this.props.match.params.products;
      post = this.props.initData.products[post_name];
      if (post) {
        this.setState({matched: true, post: post});
      }
    } else { //if it's client work
      post_name = this.props.match.params.case_study;
      post = this.props.initData.case_studies[post_name];
      if (post) {
        this.setState({matched: true, post: post});
      }
    }
  };

  getLinks() {
    const links_data = this.state.post.links;
    var links = []
    for (var link in links_data) {
      if (links_data[link].link_url.includes('http')) {
        links.push(<a target="_blank" href={links_data[link].link_url} key={link} className="text-link">{links_data[link].link_label}</a>)
      } else {
        links.push(<Link to={links_data[link].link_url} key={link} className="text-link">{links_data[link].link_label}</Link>)
      }
    }
    return links;
  }

  getRelatedProjects() {
    const case_study_data = this.state.post.related_projects;
    var case_studies = [];
    var studyData = '';
    if (this.state.caseType === 'products') { //if it's a product
      studyData = this.props.initData.products;
    } else {
      studyData = this.props.initData.case_studies;
    }
    //Gather featured case study data from the main array and push out new arrays
    var createProject = function(id) {
      for (var i in studyData) {
        if (studyData[i].ID === id) {
          var slideElements = [
            [
              studyData[i].post_title,
              studyData[i].title_image.sizes.Maximum,
              studyData[i].post_name,
              studyData[i].title_subhead
            ]
          ];
          case_studies.push(...slideElements)
        }
      }
    }
    //Find the ID of the featured Case Study and fire it off to the createProject function
    for (var key in case_study_data) {
      var caseID = case_study_data[key];
      createProject(caseID);
    }

    //push the new array data to do the rotator constructor
    var slider = [];
    slider.push(<RelatedSlider key={key} data={case_studies} type={this.state.caseType} settings=""/>)
    //console.log(case_studies);
    return slider;
  }

  getFlexibleContent() {
    const example_data = this.state.post.content;
    var examples = [];
    for (var path in example_data) {
      if (example_data[path].acf_fc_layout === 'video') {
        examples.push(<div className="video-wrap hidden" key={path}>
          <VimeoPlayer videoID={example_data[path].video_url}/>
        </div>);
      } else if (example_data[path].acf_fc_layout === 'image') {
        examples.push(<div className="image-wrap hidden" key={path}>
          <img className="desktop-img" src={example_data[path].fw_image.sizes.Maximum} alt="work-example"/>
          <img className="mobile-img" src={example_data[path].fw_image.sizes.medium} alt="work-example"/>
        </div>);
      } else if (example_data[path].acf_fc_layout === 'text_block') {
        examples.push(<div className="copy-block hidden text-content">
          <div className="textBlock" key={path} dangerouslySetInnerHTML={{
              __html: example_data[path].text_content
            }}/>
        </div>);
      } else if (example_data[path].acf_fc_layout === 'image_rotator') {
        var rotator_data = example_data[path].rotator;
        examples.push(<div className="slider-wrap hidden">
          <Slider data={rotator_data} settings="" key={path}/>
        </div>)
      } else if (example_data[path].acf_fc_layout === 'video_box') {
        examples.push(<div className="video-boxed hidden" key={path}>
          <VimeoPlayer videoID={example_data[path].video_url}/>
        </div>);
      } else if (example_data[path].acf_fc_layout === 'loop_video') {
        examples.push(<div className="video-wrap loop hidden" key={path}>
          <VimeoPlayer videoID={example_data[path].video_id} looped="looped"/>
        </div>);
      }
    }
    return examples;
  }

  render() {
    const {currentUrl} = this.state;
    const related_projects = this.state.post.related_projects;
    const partnership = this.state.post.partner_callout;
    const related_news = this.state.post.featured_ideas;
    const postID = this.state.post.ID;
    const oidValue = "00Df2000001KnCT";
    if (postID === 1340) {
      var youatCollege = true;
    };
    if (postID === 1322) {
      var manTherapy = true;
    };
    //console.log(this.state.post);
    if (this.state.matched) {
      return (<div id="case-study" className="pagewrap">
        <Helmet>
          <title>{this.state.post.meta_title}</title>
          <link rel="canonical" href={currentUrl}/>
          <meta name="description" content={this.state.post.meta_description}/>
          <meta name="twitter:card" value="summary"/>
          <meta property="og:title" content={this.state.post.meta_title}/>
          <meta property="og:type" content="article"/>
          <meta property="og:url" content={currentUrl}/>
          <meta property="og:image" content={this.state.post.social_image}/>
          <meta property="og:description" content={this.state.post.meta_description}/>
        </Helmet>
        <div className="hero-wrap">
          <div className={'content-wrap'}>
            <Link to={'/solutions'} className="crumb-link">
              <span>Solutions</span>
            </Link><br/>
            <h1 className="styled">
              {this.state.post.post_title}
            </h1>
          </div>
          <div className="hero">
            <img className="desktop" src={this.state.post.title_image.sizes.Maximum} alt='brand hero'/>
            <img className="mobile" src={this.state.post.title_image.sizes.large} alt='brand hero'/>
          </div>
        </div>
        <div className="copy-block">
          <div className="copy-wrap intro">
            <div className="left hidden">
              <h3>{this.state.post.title_subhead}</h3>
              <div className="links">
                {this.getLinks()}
              </div>
            </div>
            <div className="right hidden">
              <p dangerouslySetInnerHTML={{
                  __html: this.state.post.intro
                }}/>
            </div>
          </div>
        </div>
        <div className="work-example" style={{
            backgroundColor: this.state.post.content_background_color
          }}>
          <div className="work-wrap">
            {this.getFlexibleContent()}
          </div>
        </div>
        {
          partnership && !(youatCollege || manTherapy)
            ? (<div className={'partner'}>
              <div className="content-wrap">
                <div className="left">
                  <h3>{partnership.headline}</h3>
                  {
                    partnership.cta_destination.includes('http')
                      ? <a className="text-link" target="_blank" href={partnership.cta_destination}>{partnership.cta_text}</a>
                      : <Link className="text-link" to={partnership.cta_destination}>{partnership.cta_text}</Link>
                  }
                </div>
                <div className="right">
                  <img src={partnership.partner_logo.sizes.large} alt={partnership.title}/>
                </div>
              </div>
            </div>)
            : ('')
        }
        { //form only shows up on the youatCollege case study
          youatCollege
            ? (
              <div className="hidden">
                <div className={'partner'}>
                  <div className="content-wrap">
                    <div className="left">
                      <h3>{partnership.headline}</h3>
                    </div>
                    <div className="right">
                      <img src={partnership.partner_logo.sizes.large} alt={partnership.title}/>
                    </div>
                  </div>
                </div>
                <div className="contact-form">
                  <div className="content-wrap">
                  <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" >
                    <input type="hidden" name="recordType" id="recordType" value="012f2000000gmGd"></input>
                    <div className="left">
                      <input type="hidden" name="oid" value={oidValue} />
                      <input type="hidden" name="retURL" value="https://www.gritdigitalhealth.com/solutions" />
                      <label htmlFor="first_name">First Name*</label><input id="first_name" maxLength="40" name="first_name" size="20" type="text" required/>
                      <label htmlFor="last_name">Last Name*</label><input id="last_name" maxLength="80" name="last_name" size="20" type="text" required/>
                      <label htmlFor="title">Title</label><input id="title" maxLength="40" name="title" size="20" type="text"/>
                      <label htmlFor="email">Email*</label><input id="email" maxLength="80" name="email" size="20" type="text" required/>
                      <label htmlFor="company">School or Organization*</label><input id="company" maxLength="40" name="company" size="20" type="text" required/>
                    </div>
                    <div className="right">
                      <label>How did you hear about YOU at College?</label>
                      <select id="00Nf2000003UB1m" name="00Nf2000003UB1m" title="YOU How did you hear about YOU?">
                        <option value="">--None--</option>
                        <option value="Colleague">Colleague</option>
                        <option value="Conference">Conference</option>
                        <option value="Email from YOU at College">Email from YOU at College</option>
                        <option value="In the news">In the news</option>
                        <option value="Social media">Social media</option>
                        <option value="Web search">Web search</option>
                      </select>
                      <label>What are you most interested in learning about?</label>
                      <select id="00Nf2000003UCUV" name="00Nf2000003UCUV" title="YOU What is your interest in YOU?">
                        <option value="">--None--</option>
                        <option value="Bringing YOU to my campus">Bringing YOU to my campus</option>
                        <option value="Efficacy and Research">Efficacy and Research</option>
                        <option value="Partnership Opportunities">Partnership Opportunities</option>
                        <option value="Media Inquiries">Media Inquiries</option>
                      </select>
                        <label>Message*</label>
                        <textarea id="00Nf2000003UCUp" name="00Nf2000003UCUp" rows="9" type="text" wrap="soft" required></textarea>
                      </div>
                      <input type="submit" name="submit" value="submit" class="button" />
                      <br />
                    </form>
                  </div>
                </div>
              </div>)
        : ('')
      }
      { //form only shows up on the manTherapy case study
        manTherapy
          ? (
            <div className="hidden">
              <div className={'partner'}>
                <div className="content-wrap">
                  <div className="left">
                    <h3>{partnership.headline}</h3>
                  </div>
                  <div className="right">
                    <img src={partnership.partner_logo.sizes.large} alt={partnership.title}/>
                  </div>
                </div>
              </div>
              <div className="contact-form">
                <div className="content-wrap">
                <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" >
                  <input type="hidden" name="recordType" id="recordType" value="012f2000000gmGn"></input>
                  <div className="left">
                    <input type="hidden" name="oid" value={oidValue} />
                    <input type="hidden" name="retURL" value="https://www.gritdigitalhealth.com/solutions" />
                    <label htmlFor="first_name">First Name*</label><input id="first_name" maxLength="40" name="first_name" size="20" type="text" required/>
                    <label htmlFor="last_name">Last Name*</label><input id="last_name" maxLength="80" name="last_name" size="20" type="text" required/>
                    <label htmlFor="company">Organization*</label><input id="company" maxLength="40" name="company" size="20" type="text" required/>
                    <label htmlFor="email">Email*</label><input id="email" maxLength="80" name="email" size="20" type="text" required/>
                  </div>
                  <div className="right">
                    <label>I am representing*</label>
                    <select  id="00Nf2000003UCUu" name="00Nf2000003UCUu" title="MT I am representing*" required><option value="">--None--</option><option value="A corporation or privately owned business">A corporation or privately owned business</option>
                      <option value="A nonprofit organization">A nonprofit organization</option>
                      <option value="A private practice">A private practice</option>
                      <option value="A state/county">A state/county</option>
                      <option value="Myself or an individual">Myself or an individual</option>
                      <option value="Other">Other</option>
                      <option value="Media/Press">Media/Press</option>
                    </select>
                    <label htmlFor="street">Street Address</label><input name="street" id="street" type="text"></input>
                    <div class="inline"><label htmlFor="city">City</label><input id="city" maxlength="40" name="city" size="20" type="text" /></div>
                    <div class="inline"><label htmlFor="state">State/Province</label><input id="state" maxlength="20" name="state" size="20" type="text" /></div>
                    <div class="inline"><label htmlFor="zip">Zip</label><input id="zip" maxlength="20" name="zip" size="20" type="text" /></div>
                    <label>Message*</label>
                    <textarea  id="00Nf2000003UCVJ" name="00Nf2000003UCVJ" rows="4" type="text" wrap="soft" required></textarea>
                  </div>
                  <input type="submit" name="submit" value="submit" class="button" />
                  <br />
                  </form>
                </div>
              </div>
            </div>)
      : ('')
    }
        {
          related_news
            ? (<IdeasBlock initData={this.state.post}/>)
            : ('')
        }
        {
          related_projects
            ? (<div className={Number(related_projects.length) === 1
                ? "related-projects single hidden"
                : "related-projects hidden"}>
              <div className="content-wrap">
                <h2>Related Work</h2>
              </div>
              {this.getRelatedProjects()}
            </div>)
            : ('')
        }
        {/*<div className="extended-footer">
          <div className="section-wrap">
            <div className="partnership-wrap">
              <div className="content-wrap">
                <h3>Thinking about a partnership?</h3>
                <Link to={"/contact"} className="text-link">Contact Us CTA</Link>
              </div>
            </div>
            <div className="demo-wrap">
              <div className="content-wrap">
                <h3>Thinking about a partnership?</h3>
                <Link to={"/contact"} className="text-link">Contact Us CTA</Link>
              </div>
            </div>
          </div>
        </div>*/
        }
      </div>);
    } else {
      return (<div id="case-study">
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        Page Not Found
      </div>);
    }
  }
}

export default CaseStudy;
