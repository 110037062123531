import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import JobForm from '../JobForm';
import {Link} from 'react-router-dom';
import './style.scss';

class JobListing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      matched: false,
      post: {},
      isToggleOn: false,
      currentUrl: window.location.href
    };
  }

  componentWillMount() {
    const post_name = this.props.match.params.job_listing;
    const post = this.props.initData.job_listings[post_name];
    if (post)
      this.setState({matched: true, post: post});
    }
  getRoles() {
    const role_data = this.state.post.job_roles;
    var Roles = []
    for (var key in role_data) {
      Roles.push(<li key={key}>{role_data[key].job_role}</li>);
    }
    return Roles;
  }
  getQuals() {
    const role_data = this.state.post.job_qualifications;
    var Quals = []
    for (var key in role_data) {
      Quals.push(<li key={key}>{role_data[key].job_qual}</li>);
    }
    return Quals;
  }

  getDesc() {
    const str = this.state.post.description;
    return (<div className="desc" dangerouslySetInnerHTML={{
        __html: str
      }}/>);
  }

  render() {
    const role_data = this.state.post.job_roles;
    const qual_data = this.state.post.job_qualifications;
    const {currentUrl} = this.state;
    if (this.state.matched) {
      return (<div id="job_listing" className="pagewrap">
        <Helmet>
          <title>{this.state.post.post_title} | Grit Digital Health</title>
          <link rel="canonical" href={currentUrl}/>
          <meta name="description" content={this.state.post.meta_description}/>
          <meta name="twitter:card" value="summary"/>
          <meta property="og:title" content={this.state.post.meta_title}/>
          <meta property="og:type" content="article"/>
          <meta property="og:url" content={currentUrl}/>
          <meta property="og:image" content={this.state.post.social_image}/>
          <meta property="og:description" content={this.state.post.meta_description}/>
        </Helmet>
        <div className={'content-wrap'}>
          <div className="type-wrap">
            <Link to={'/careers'} className="crumb-link">
              <span>Careers
              </span>
            </Link><br/>
            <h1>
              {this.state.post.post_title}
            </h1>
          </div>
        </div>
        <div className="postBody">
          <div className="content-wrap">
            {this.getDesc()}
          </div>
          <div className="content-wrap">
            {/* if theres role data render it otherwise leave it blank */}
            {
              role_data
                ? (<div className="roles">
                  <h3>{this.state.post.role_title}</h3>
                  {this.getRoles()}
                </div>)
                : ('')
            }
            {/* if theres Qualification data render it otherwise leave it blank */}
            {
              qual_data
                ? (<div className="roles">
                  <h3>{this.state.post.qual_title}</h3>
                  {this.getQuals()}
                </div>)
                : ('')
            }
          </div>
        </div>
        <JobForm title={this.state.post.post_name}/>
      </div>);
    } else {
      return (<div id="job_listing">
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        Page Not Found
      </div>);
    }
  }
}

export default JobListing;
