import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import Button from '../Button';
import './style.scss';

class Careers extends Component {

  constructor() {
    super();
    this.state = {};
  }

  jobListings() {
    const listing_data = this.props.initData.job_listings;
    var Listings = []
    for (var key in listing_data) {
      Listings.push(<li key={key}>
        <Button className="text-link black" path={'/careers/' + listing_data[key].post_name} text={listing_data[key].post_title}/>
      </li>);
    }
    return Listings;
  }

  render() {
    return (<div id="careers" className="pagewrap">
      <Helmet>
        <title>{this.props.initData.careers.meta_title}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/careers"/>
        <meta name="description" content={this.props.initData.careers.meta_description}/>
        <meta name="twitter:card" value="summary"/>
        <meta property="og:title" content={this.props.initData.careers.meta_title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content={"https://www.gritdigitalhealth.com/careers"}/>
        <meta property="og:image" content={this.props.initData.careers.social_image}/>
        <meta property="og:description" content={this.props.initData.careers.meta_description}/>
      </Helmet>
      <div className={'content-wrap'}>
        <div className="crumb">
          <span>Careers
          </span>
        </div><br/>
        <h1 className="styled">
          {this.props.initData.careers.headline}
        </h1>
      </div>
      <div className="body-wrap">
        <div className="content-wrap">
          <div className="type-wrap">
            <p>{this.props.initData.careers.intro_text}</p>
            <p dangerouslySetInnerHTML={{
                __html: this.props.initData.careers.hiring_text
              }}/>
            <a href="mailto:career@gritdigitalhealth.com" className="button">Send Your Info</a>
          </div>
          <div className="description">
            <div className="content-wrap">
              <div className="body">
                <div className="link-list">
                  <h3>Open Positions</h3>
                  {this.jobListings()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

export default Careers;
