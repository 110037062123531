import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import FooterCallout from '../FooterCallout';
import './style.scss';

/*
  Component Structure:

*/

class CapabilitiesPage extends Component {

  constructor() {
    super();
    this.state = {};
  }

  getBrandingCapes() { //branding capabilities
    var Items = []
    var ItemData = this.props.initData.capabilities.branding.roles;
    var items_sorted = [];
    for (var key in ItemData) {
      items_sorted.push(ItemData[key]);
    }
    items_sorted.sort(function(a, b) {
      return a.term_order - b.term_order
    });
    items_sorted.forEach(function(link, index) {
      /* push final markup to array to be returned */
      Items.push(<li key={index}><h3 dangerouslySetInnerHTML={{
          __html: link.name
        }}/></li>);
    });
    return Items;
  }

  getDesignCapes() { //design capabilities
    var Items = []
    var ItemData = this.props.initData.capabilities.design.roles;
    var items_sorted = [];
    for (var key in ItemData) {
      items_sorted.push(ItemData[key]);
    }
    items_sorted.sort(function(a, b) {
      return a.term_order - b.term_order
    });
    items_sorted.forEach(function(link, index) {
      /* push final markup to array to be returned */
      Items.push(<li key={index}><h3 dangerouslySetInnerHTML={{
          __html: link.name
        }}/></li>);
    });
    return Items;
  }

  getMediaCapes() { //media capabilities
    var Items = []
    var ItemData = this.props.initData.capabilities.media.roles;
    var items_sorted = [];
    for (var key in ItemData) {
      items_sorted.push(ItemData[key]);
    }
    items_sorted.sort(function(a, b) {
      return a.term_order - b.term_order
    });
    items_sorted.forEach(function(link, index) {
      /* push final markup to array to be returned */
      Items.push(<li key={index}><h3 dangerouslySetInnerHTML={{
          __html: link.name
        }}/></li>);
    });
    return Items;
  }

  getTechCapes() { //technology capabilities
    var Items = []
    var ItemData = this.props.initData.capabilities.technology.roles;
    var items_sorted = [];
    for (var key in ItemData) {
      items_sorted.push(ItemData[key]);
    }
    items_sorted.sort(function(a, b) {
      return a.term_order - b.term_order
    });
    items_sorted.forEach(function(link, index) {
      /* push final markup to array to be returned */
      Items.push(<li key={index}><h3 dangerouslySetInnerHTML={{
          __html: link.name
        }}/></li>);
    });
    return Items;
  }

  getClientGroups() {
    var experience_links = [];
    const experience_data = this.props.initData.industries;

    for (var key in experience_data) {
      experience_links.push(<Link className="text-link black" key={key} to={"/industries/" + experience_data[key].post_name}>
        <span>{experience_data[key].post_title}</span>
      </Link>)
    }
    return experience_links;
  }

  render() {
    return (<div id="Capabilities" className="pagewrap">
      <Helmet>
        <title>{this.props.initData.capabilities_page.meta_title}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/capabilities"/>
        <meta name="description" content={this.props.initData.capabilities_page.meta_description}/>
        <meta name="twitter:card" value="summary"/>
        <meta property="og:title" content={this.props.initData.capabilities_page.meta_title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content="https://www.gritdigitalhealth.com/capabilities"/>
        <meta property="og:image" content={this.props.initData.capabilities_page.social_image}/>
        <meta property="og:description" content={this.props.initData.capabilities_page.meta_description}/>
      </Helmet>
      <div className={'content-wrap'}>
        <div className="crumb">
          <span>About</span>
        </div><br/>
        <h1>
          {this.props.initData.capabilities_page.headline}
        </h1>
      </div>
      <div id="client-experience" className="hidden">
        <div className={'content-wrap'}>
          <div className={'experience-content'}>
            <div dangerouslySetInnerHTML={{
                __html: this.props.initData.capabilities_page.intro
              }}/>
          </div>
          <div className="client-list">
            <h3>Industries</h3>
            {this.getClientGroups()}
            <br/>
          </div>
        </div>
      </div>
      <section className="capabilities_list hidden">
        <div className="cap-wrap">
          <div className={'content-wrap'}>
            <div className="caps-list">
              <div className="caps-slide">
                <Link to={"/capabilities/" + this.props.initData.capabilities.branding.post_name} className="capabilities_home">
                  <div className="header-wrap">
                    <h2 className="header-link">
                      {this.props.initData.capabilities.branding.post_title}
                    </h2>
                  </div>
                  <ul>
                    {this.getBrandingCapes()}
                  </ul>
                </Link>
                <Link to={"/capabilities/" + this.props.initData.capabilities.design.post_name} className="capabilities_home">
                  <div className="header-wrap">
                    <h2 className="header-link">
                      {this.props.initData.capabilities.design.post_title}
                    </h2>
                  </div>
                  <ul>
                    {this.getDesignCapes()}
                  </ul>
                </Link>
                <Link to={"/capabilities/" + this.props.initData.capabilities.media.post_name} className="capabilities_home">
                  <div className="header-wrap">
                    <h2 className="header-link">
                      {this.props.initData.capabilities.media.post_title}
                    </h2>
                  </div>
                  <ul>
                    {this.getMediaCapes()}
                  </ul>
                </Link>
                <Link to={"/capabilities/" + this.props.initData.capabilities.technology.post_name} className="capabilities_home">
                  <div className="header-wrap">
                    <h2 className="header-link">
                      {this.props.initData.capabilities.technology.post_title}
                    </h2>
                  </div>
                  <ul>
                    {this.getTechCapes()}
                  </ul>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterCallout calloutData={this.props.initData.capabilities_page.footer_callout} />
    </div>);
  }
}

export default CapabilitiesPage;
