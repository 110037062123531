import React, {Component} from 'react';
import './style.scss';

class VimeoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activated: false,
      vimeo: [],
      isLoading: true
    };
  }

  componentDidMount() {
    fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/` + this.props.videoID.trim()).then(result => result.json()).then(vimeo => this.setState({vimeo, isLoading: false}));
  }

  activateVideo() {
    this.setState({activated: true});
  }

  getThumbnail(className) {
    var thumbnail = this.state.vimeo.thumbnail_url;
    var chop = thumbnail.split('video/')[1];
    var imageID = chop.split('_')[0];
    //console.log(thumbnail);
    return (<img src={'https://i.vimeocdn.com/filter/overlay?src0=https://i.vimeocdn.com/video/' + imageID + '.jpg&src1=http://f.vimeocdn.com/p/images/crawler_play.png'} alt="Video - Click to Play" onClick={() => {
        this.activateVideo()
      }} className={className + ' thumbnail'}/>)
  }

  render() {
    return (<div id="videoPlayer">
      {
        this.state.isLoading
        //this is empty while content is loaded
      }
      {
        !this.state.isLoading &&
        //we're ready to rock, do it to it.
        <div>
          {
            this.props.looped && <div>
                <iframe src={"https://player.vimeo.com/video/" + this.props.videoID + "?autoplay=1&loop=1&autopause=0&muted=1&controls=0"} width="640" height="360" frameBorder="0" allowFullScreen="allowFullScreen" title={"Cactus video"} allow="autoplay"></iframe>
              </div>
          }
          {
            this.state.activated &&
            //the video player to be loaded on click
            <div>
                {this.getThumbnail('active')}
                <iframe src={"https://player.vimeo.com/video/" + this.props.videoID + "?autoplay=1"} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen="allowFullScreen" title={"Cactus video"}></iframe>
              </div>
          }
          {
            !this.state.activated &&
            //this is the static that should initally be on page load until the user interacts
            <div>
                {this.getThumbnail()}
              </div>
          }
        </div>
      }
      </div>)
  }
}

export default VimeoPlayer;
