import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './style.scss';

class IdeasBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ideaData: this.props.initData,
    };
  }

  getIdeas(){
    var ideas = [];
    var data = this.state.ideaData.featured_ideas;
    for (var key in data) {
      var imageData = data[key].post_featured_image.sizes;
      ideas.push(
        <Link key={key} className="ideaBlock" to={'/ideas/' + data[key].post.post_name}>
          <img src={imageData ? data[key].post_featured_image.sizes.large : ''} alt="stuff"/>
          <div className="cardContent">
            <h4>{data[key].post.post_title}</h4>
            <p>{data[key].post.post_excerpt}</p>
          </div>
        </Link>
      );
    }
    return ideas;
  }

  render() {
    return (
    <div id="ideas" className='hidden'>
      <div className="intro">
        <h2>Ideas</h2>
        <p>{this.state.ideaData.ideas_subhead}</p>
      </div>
      <div className="block-wrap">
        {this.getIdeas()}
      </div>
      <Link to="/ideas" className="button">See all ideas</Link>
    </div>
    );
  }
}

export default IdeasBlock;
