import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import FooterCallout from '../FooterCallout';
import './style.scss';

class Work extends Component {

  constructor() {
    super();
    this.state = {};
  }

  getItems(e, type) {
    const case_study_data = e
    var work_page = ''

    if (type === 'products'){
      work_page = 'products'
    }else{
      work_page = 'work'
    }

    //Case Studies
    var case_studies_sorted = [];
    for (var key in case_study_data) {
      case_studies_sorted.push(case_study_data[key]);
    }
    case_studies_sorted.sort(function(a, b) {
      return a.menu_order - b.menu_order
    });

    //Create Elements
    var case_studies = [];
    case_studies_sorted.forEach(function(case_study, index) {
      const post_size = case_study.large_small;
      case_studies.push(<Link key={index} className={post_size + " hidden"} to={'/' + work_page + '/' + case_study.post_name}>
        <div className={"main-img"}>
          <img className="desktop-img" src={case_study.main_image.sizes.large} alt={case_study.post_title}/>
          <img className="mobile-img" src={case_study.main_image.sizes.medium} alt={case_study.post_title}/>
          <div className="container">
            <div className="case-study-link"/>
          </div>
        </div>
        <div className={'post-content'}>
          <div className="title mobile">{case_study.post_title}</div>
          <h4>{case_study.subhead_work_page}</h4>
          <div className="text-link black desktop">{case_study.post_title}</div>
        </div>
      </Link>);
    }, this);
    return case_studies;
  }

  render() {
    return (
      <div id="work" className="pagewrap">
      <Helmet>
        <title>{this.props.initData.work.meta_title}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/solutions"/>
        <meta name="description" content={this.props.initData.work.meta_description}/>
        <meta name="twitter:card" value="summary"/>
        <meta property="og:title" content={this.props.initData.work.meta_title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content="https://www.gritdigitalhealth.com/solutions"/>
        <meta property="og:image" content={this.props.initData.work.social_image}/>
        <meta property="og:description" content={this.props.initData.work.meta_description}/>
      </Helmet>
      <div className="bodyWrap">
        <div className={'content-wrap hidden'}>
          <h1>Our Products</h1>
          <h3>{this.props.initData.work.products_subhead}</h3>
          <div className="case_studies">
            {this.getItems(this.props.initData.products, 'products')}
          </div>
        </div>
        <div className={'content-wrap hidden'}>
          <h1>Client Work</h1>
          <h3>{this.props.initData.work.work_subhead}</h3>
          <div className="case_studies client-work">
            {this.getItems(this.props.initData.case_studies, 'work')}
          </div>
        </div>
      </div>
      <FooterCallout calloutData={this.props.initData.work.footer_callout} />
    </div>);
  }
}

export default Work;
