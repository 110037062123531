import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import FooterCallout from '../FooterCallout';
import './style.scss';

class Contact extends Component {

  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (<div id="contact" className="pagewrap">
      <Helmet>
        <title>{this.props.initData.contact.meta_title}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/contact"/>
        <meta name="description" content={this.props.initData.contact.meta_description}/>
        <meta name="twitter:card" value="summary"/>
        <meta property="og:title" content={this.props.initData.contact.meta_title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content={"https://www.gritdigitalhealth.com/contact"}/>
        <meta property="og:image" content={this.props.initData.contact.social_image}/>
        <meta property="og:description" content={this.props.initData.contact.meta_description}/>
      </Helmet>
      <div className={'content-wrap intro'}>
        <h1 className="styled">
          {this.props.initData.contact.headline}
        </h1>
        <h3>
          {this.props.initData.contact.subhead}
        </h3>
      </div>
      <div className="general hidden">
        <div className="content-wrap">
          <div className="contact-info">
            <h4>Send us an email.</h4>
              <a href={'mailto:' + this.props.initData.contact.email} dangerouslySetInnerHTML={{
                  __html: this.props.initData.contact.email_formatting
                }}/>
          </div>
          <div className="contact-info">
            <h4>Give us a call.</h4>
              <a href={'tel:3034557545'}>{this.props.initData.contact.phone}</a>
          </div>
          <div className="contact-info">
            <h4>We're located at.</h4>
              <a href="https://goo.gl/maps/UpvsyXegaQzNCy8a8" target='_blank' rel="noopener noreferrer">{this.props.initData.contact.address}</a>
          </div>
        </div>
      </div>
      <FooterCallout calloutData={this.props.initData.contact.footer_callout}/>
    </div>);
  }
}

export default Contact;
