import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import ToolkitModal from "../ToolkitModal/index";
import "./style.scss";

class MarketingToolkit extends Component {
  constructor() {
    super();
    this.state = {
      matched: false,
      post: {},
      refs: [],
    };

    this.onClick = this.onClick.bind(this);

    // * Refs for autoscroll are added in componentWillMount *
  }

  componentWillMount() {
    const post_name = this.props.match.params.toolkit;
    const post = this.props.initData.marketing_toolkit[post_name];
    if (post) {
      this.setState({ matched: true, post: post });

      //Create refs for autoscroll for each section
      post.section.forEach((section, i) => {
        const refName = this.idString(section.header);
        this[refName] = React.createRef();
      });
    }
  }

  idString(input) {
    return input
      .toString()
      .toLowerCase()
      .split(" ")
      .join("-");
  }

  onClick(e) {
    e.preventDefault();
    const ref = e.target.dataset.ref;
    window.scrollTo({
      top: this[ref].current.offsetTop,
      behavior: "smooth"
    });
  }

  getQuicklinks() {
    if (this.state.matched) {
      const quicklinks = this.state.post.section.map((section, i) => {
        return (
          <div key={i} className="quicklink">
            <a
              href="/"
              onClick={this.onClick}
              data-ref={this.idString(section.header)}
            >
              {section.header}
            </a>
          </div>
        );
      });

      return quicklinks;
    }
  }

  getToolkitSections() {
    if (this.state.matched) {
      var sections = this.state.post.section.map((section, i) => {
        var assets = section.assets.map((asset, idx) => {
          return (
            <div key={idx} className="asset">
              <div className="asset-text">
                <h3>{asset.title}</h3>
                <p>{asset.description}</p>
                <a
                  href={asset.link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {asset.link.title}
                </a>
              </div>
              <div className="asset-img">
                <a
                  href={asset.link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {asset.image ? <img src={asset.image.sizes.medium} alt="" /> : '' }

                </a>
              </div>
            </div>
          );
        });
        return (
          <div
            key={i}
            className="section content-wrap"
            ref={this[this.idString(section.header)]}
          >
            <h3>{section.header}</h3>
            <div className="assets">{assets}</div>
          </div>
        );
      });

      return sections;
    }
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{this.state.post.header.toString()} - Marketing Toolkit</title>
          <link rel="canonical" href={'https://www.gritdigitalhealth.com/' + this.idString(this.state.post.header)} />
          <meta
            name="description"
            content={this.state.post.description.toString()}
          />
          <meta name="twitter:card" value="summary" />
          <meta
            property="og:title"
            content={this.state.post.header.toString()}
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={'https://www.gritdigitalhealth.com/' + this.idString(this.state.post.header)}
          />
          <meta
            property="og:image"
            content={this.state.post.header.toString()}
          />
          <meta
            property="og:description"
            content={this.state.post.description.toString()}
          />
        </Helmet>
        <div className="marketing-toolkit pagewrap">
          <ToolkitModal pw={this.state.post.password} includePw={this.state.post.include_password_protection}  />
          <div className="content-wrap intro">
            <div className="copy-block">
              <div className="copy-wrap intro">
                <div className="left fade-in-element">
                  <h1>{this.state.post.header.toString()}</h1>
                  <h3>{this.state.post.subhead.toString()}</h3>
                </div>
                <div className="right fade-in-element">
                  <p>{this.state.post.description.toString()}</p>
                </div>
              </div>
            </div>

            <br />
          </div>
          <div className="quicklinks">
            <div className="content-wrap">
              <p>Jump to section:</p>
              {this.getQuicklinks()}
            </div>
          </div>
          <div className="sections">{this.getToolkitSections()}</div>
          <div className="fill"></div>
        </div>
      </Fragment>
    );
  }
}

export default MarketingToolkit;
