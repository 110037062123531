import React from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
require('./style.scss');

//create slider wrapper and call the slide builder
function SimpleSlider(props) {

  //Set base slider settings
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false
        }
      }
    ]
  };

  return (<Slider {...settings} className="slider">
    {SlideBuilder(props)}
  </Slider>);
}

//build out the individual slides
function SlideBuilder(props) {
  var slideData = props.data;
  var slideBuild = [];
  var countCS = 0;
  var totalCS = slideData.length;
  for (var key in slideData) {
    countCS = countCS + 1;
    slideBuild.push(
      <div className="wrapper" key={key}>
      <div className="slide-title">
        <h3>{slideData[key][0]}</h3>
        <h4>{slideData[key][3]}</h4>
      </div>
      <div key={key} className="slide">
        <div className="number-wrap">
          <div className="count">{countCS}</div>
          <div className="total">of&nbsp;&nbsp;<span>{totalCS}</span>
          </div>
        </div>
        <Link to={props.type === "products" ? "/products/" + slideData[key][2] : "/work/" + slideData[key][2]}>
          <img src={slideData[key][1]} alt={slideData[key][0]}/>
        </Link>
      </div>
    </div>)
  }
  return slideBuild;
}

export default SimpleSlider;
