import React, {useState} from "react";
import share from './img/share-icon.svg'
import shareWhite from './img/share-icon-white.svg'
import facebook from './img/facebook.svg'
import mail from './img/mail-icon.svg'

export const Share = ({color, title, href}) => {
    const [shareActive, setShareActive] = useState(false);
    // const handleShareEnter = () => {
    //     setShareActive(true)
    // }
    const handleShareLeave = () => {
        setShareActive(false)
    }

    const handleClick = () => {
        setShareActive(!shareActive)
    }
    return (
        // <div className="share-wrap" onMouseEnter={handleShareEnter} onMouseLeave={handleShareLeave}>
        <div className="share-wrap" onMouseLeave={handleShareLeave}>
            <button className='share' onClick={handleClick } >
                {title || "Share"}
                <img src={color === 'white' ? shareWhite : share } height='20px' width='20px' alt="share" />
            </button>
            <div className={`share-link-wrap ${shareActive ? 'active' : 'not-active'}`}>
                <a  className="facebook" href={href} target="_blank" rel="noopener noreferrer">
                    
                    <img src={facebook}  height='30px' alt="share" />
                </a>
                <a className="mail" href={`mailto:?subject=Holiday Recipes&body=https://gritdigitalhealth.com/recipes`} target="_blank" rel="noopener noreferrer">
                    <img src={mail}  width='16px' alt="share" />

                </a>
            </div>
        </div>
    )
}