import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import FooterCallout from '../FooterCallout';
import './style.scss';

class Partnerships extends Component {

  constructor() {
    super();
    this.state = {};
  }

  getPartners() {
    const partnerData = this.props.initData.partners;
    var partners = [];
    for (var partner in partnerData) {
      partners.push(<div className="partners-wrap hidden" key={partner}>
        <div className="image">
          <img src={partnerData[partner].partner_image.sizes.large} alt={partnerData[partner].partner_image.title}/>
        </div>
        <div className="content">
          <h3>{partnerData[partner].name}</h3>
          <p className="title">{partnerData[partner].partner_title}</p>
          <p className="body" dangerouslySetInnerHTML={{
              __html: partnerData[partner].descriptive_text
            }}/>
          <a href="/contact" className="button">Reach out to Grit</a>
        </div>
        <br/>
      </div>);
    }
    return partners;
  }

  getLogos() {
    const logoData = this.props.initData.partnerships.partner_logos;
    var Logos = [];
    for (var logo in logoData) {
      Logos.push(<div className="logo-wrap" key={logo}>
        <img src={logoData[logo].partner_logo.sizes.medium} alt={logoData[logo].partner_logo.title}/>
      </div>);
    }
    return Logos;
  }

  render() {
    return (<div id="Partnerships" className="pagewrap">
      <Helmet>
        <title>{this.props.initData.partnerships.meta_title}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/partners"/>
        <meta name="description" content={this.props.initData.partnerships.meta_description}/>
        <meta name="twitter:card" value="summary"/>
        <meta property="og:title" content={this.props.initData.partnerships.meta_title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content="https://www.gritdigitalhealth.com/partners"/>
        <meta property="og:image" content={this.props.initData.partnerships.social_image}/>
        <meta property="og:description" content={this.props.initData.partnerships.meta_description}/>
      </Helmet>
      <div className='content-wrap'>
        <Link to={'/'} className="button">Reach out to Grit</Link>
        <h1>
          {this.props.initData.partnerships.headline}
        </h1>
        <h3>{this.props.initData.partnerships.subhead}</h3>
      </div>
      <div className="logos-wrap">
        <div className="content-wrap">
          {this.getLogos()}
        </div>
      </div>
      <div className="partners-wrap">
        {this.getPartners()}
      </div>
      <FooterCallout calloutData={this.props.initData.partnerships.footer_callout}/>
    </div>);
  }
}

export default Partnerships;
