import React, { Component } from 'react';
import './style.scss';

class JobForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      number: "",
      portfolio: "",
      filethree: "",
      submitText: "Submit Application",
      submitState: "",
      joblistingname:props.title
    };
    this.filethree_obj = null;
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleFile = event => {
    if(event.target.files.length !== 0){
      var label = event.target.parentNode.getElementsByTagName('label')[0];
      var span = label.getElementsByTagName('span')[0];
      span.innerText = event.target.files[0].name;
      label.className = 'outline-label active';
      switch(event.target.name){
        case 'filethree':
          this.filethree_obj = event.target.files[0];
          break;
        default:
          break;
      }
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({submitText: 'One Sec...'});
      console.log(this.state);

      var formData  = new FormData();
      formData.append('form-name', 'job-listing-form');
      formData.append('name', this.state.joblistingname + ': ' + this.state.name);
      formData.append('email', this.state.email);
      formData.append('number', this.state.number);
      formData.append('portfolio', this.state.portfolio);
      //formData.append('fileone', this.fileone_obj);
      //formData.append('filetwo', this.filetwo_obj);
      formData.append('filethree', this.filethree_obj);
      formData.append('joblistingname', this.state.joblistingname);

      //Submit the form
      fetch("/", {
        method: "POST",
        body: formData
      })
      .then(res => {
        console.log('form submit', res);
        var subButton = document.getElementById('submitButton');
        subButton.disabled = true;
        this.setState({submitText: 'Form Submitted'});
        alert("Thanks! We'll review your submission and if we think you're a good fit we'll contact you shortly.");
      })
      .catch(error => alert(error));
    // }

  };

  render(){
          const { name, email, number, portfolio, /*fileone, filetwo,*/ filethree, joblistingname} = this.state;
          console.log(joblistingname);
    return(
      <div id="job_form">
        <div className="form content-wrap">
          <form name="job-listing-form" id="job-listing-form" onSubmit={this.handleSubmit} netlify-honeypot="bot-field">
              <input type="hidden" name="joblistingname" value={joblistingname} />
              <p className="noshow">
                <label>Don’t fill this out if you're human: <input name="bot-field" type="text" /></label>
              </p>
              <div className="row">
                <label>What’s your name? </label><input type="text"  placeholder="First &amp; Last Name" name="name" value={name} onChange={this.handleChange}/>
              </div>
              <div className="row">
                <label>What’s your email?</label><input type="email" name="email" placeholder="email@example.com" value={email} onChange={this.handleChange}/>
              </div>
              <div className="row">
                <label>What’s your number?</label><input type="tel" name="number" placeholder="555 - 555 - 5555" value={number} onChange={this.handleChange}/>
              </div>
              <div className="row portfolio">
                <label>Where’s your portfolio?<div className="tooltip"/></label>
                <input type="text" name="portfolio" placeholder="www.example.com" value={portfolio} onChange={this.handleChange}/>
              </div>
              <div className="row">
                <label className="col">Send us your resume.</label>
                  <div className="col-2">
                    <div className="box block">
                      <input className='input-file' type='file' id="file-3" name="filethree" onChange={this.handleFile} value={filethree} />
                      <label htmlFor="file-3" className="outline-label"><span>Upload Resume</span></label>
                    </div>
                  </div>
              </div>
              <div className="row">
                <label>All finished?</label>
                  <button id="submitButton" type="submit" className="button">{this.state.submitText}</button>
              </div>
          </form>
        </div>
      </div>
      )
    }
  }

export default JobForm;
