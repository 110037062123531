import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import TextImageLockup from '../TextImageLockup';
import IdeasBlock from '../IdeasBlock';
import FooterCallout from '../FooterCallout';
import HeroSlider from '../HeroSlider';
import './style.scss';

class Home extends Component {

  constructor () {
    super();
    this.state = {
      rotator: 'hide',
    };
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  shuffle(array) {

  	var currentIndex = array.length;
  	var temporaryValue, randomIndex;

  	// While there remain elements to shuffle...
  	while (0 !== currentIndex) {
  		// Pick a remaining element...
  		randomIndex = Math.floor(Math.random() * currentIndex);
  		currentIndex -= 1;

  		// And swap it with the current element.
  		temporaryValue = array[currentIndex];
  		array[currentIndex] = array[randomIndex];
  		array[randomIndex] = temporaryValue;
  	}

	return array;

  };

  getLogoRotator(){
    var logos = [];
    //get array from json
    const rotator_data = this.props.initData.home.client_logos;
    //randomize array
    var randomData = this.shuffle(rotator_data);
    //get first 8 in array
    var firstEight = randomData.slice(0,16);
    //populate logos based on first 8 Items of array
    for (var path in firstEight) {
      logos.push(
        <div className={"logo"} key={path}>
          <div className="image" style={{backgroundImage: `url(${firstEight[path].client_logo})`}}/>
        </div>
      );
    }
    return logos;
  }

  showRotator(){
    if (this.state.rotator === 'hide'){
      //this.setState({rotator: 'show'});
    }else{
      //don't do nuthin
    }
  }

  render(){
    return (
      <div id="home" className="pagewrap">
        <Helmet>
          <title>{this.props.initData.home.meta_title}</title>
          <link rel="canonical" href="https://www.gritdigitalhealth.com" />
          <meta name="description" content={this.props.initData.home.meta_description} />
          <meta name="twitter:card" value="summary" />
          <meta property="og:title" content={this.props.initData.home.meta_title} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content="https://www.gritdigitalhealth.com" />
          <meta property="og:image" content={this.props.initData.home.social_image} />
          <meta property="og:description" content={this.props.initData.home.meta_description} />
        </Helmet>
        {/* Home Top Feature */}
        <section className="hero">
          <div className="text-wrap">
              <h1>We create <Link to={'/solutions'}><span onMouseOver={this.showRotator.bind(this)}>behavioral health</span></Link></h1><br />
              <h1><Link to={'/solutions'}><span onMouseOver={this.showRotator.bind(this)}>and wellbeing solutions</span></Link></h1><br />
              <h1>through design and technology</h1>
          </div>
          <div className={'rotator-wrap ' + this.state.rotator}>
            <Link to={'/solutions'} className={"rotator"}>
              <HeroSlider data={this.props.initData.home.hero_images} />
            </Link>
            <Link to={'/solutions'} className="rotatorText">View Work</Link>
          </div>
        </section>
        <TextImageLockup lockupData={this.props.initData.home.lockup} />
        <div className="workCallout hidden">
          <p>See all of the great products we're making.</p>
          <Link to="/solutions" className="button">View all of our work</Link>
        </div>
        {/*
        <section className="client-experience hidden">
          <div className="content-wrap">
            <div className="client-content">
              <h2>Partnerships</h2>
              <p>We partner with organizations that are approaching mental health through unique & technical solutions.</p>
            </div>
            <div className="client-logos-wrap">
              <div className="client-logos">
                {this.getLogoRotator()}
              </div>
            </div>
            <Link to={'/capabilities'} title="Experience Categories" className="button">Explore Partnership</Link>
            <br className="clear" />
          </div>
        </section>
        */}
        <IdeasBlock initData={this.props.initData.home} />
        <FooterCallout calloutData={this.props.initData.home.footer_callout} />
      </div>
    );
  }
}

export default Home;
