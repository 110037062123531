// import { Helmet } from "react-helmet";
import React from "react";
import { Link } from "react-router-dom";

import slugify from 'slugify';


export const RecipeCard = ({ title, time, category, src }) => {
    return (
        <Link className={`recipe-card ${slugify(category, { lower: true, strict: true })}`} to={`/recipes/${slugify(title, { lower: true, strict: true })}`} >
            <img src={src} alt={title} />
        </Link>
    )
}