import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import './style.scss';

class Capability extends Component {

  constructor() {
    super();
    this.state = {
      matched: false,
      post: {}
    };
  }

  componentWillMount() {
    const post_name = this.props.match.params.capability;
    const post = this.props.initData.capabilities[post_name];
    if (post)
      this.setState({matched: true, post: post});
    }

  getItems() {
    var Items = []
    var ItemData = this.state.post.roles;
    var items_sorted = [];
    for (var key in ItemData) {
      items_sorted.push(ItemData[key]);
    }
    items_sorted.sort(function(a, b) {
      return a.term_order - b.term_order
    });
    items_sorted.forEach(function(link, index) {
      /* push final markup to array to be returned */
      Items.push(<li key={index}><h2 dangerouslySetInnerHTML={{
          __html: link.name
        }}/></li>);
    });
    return Items;
  }

  getCapabilities() {
    const pageTitle = this.state.post.post_title;
    const capa_data = this.props.initData.capabilities;
    var capabilities = [];

    var capabilities_sorted = [];
    for (var path in capa_data) {
      capabilities_sorted.push(capa_data[path]);
    }
    capabilities_sorted.sort(function(a, b) {
      return a.menu_order - b.menu_order
    });

    for (var key in capabilities_sorted) {
      if (capabilities_sorted[key].post_title === pageTitle) {
        //current capability so don't list it
      } else {
        capabilities.push(<Link className={"capabilities_home"} key={key} to={"/capabilities/" + capabilities_sorted[key].post_name}>
          <h3 className="header-link">{capabilities_sorted[key].post_title}</h3>
          {
            capabilities_sorted[key].roles.map((sub, i) => {
              return (<li key={i} dangerouslySetInnerHTML={{
                  __html: sub.name
                }}/>)
            })
          }
        </Link>);
      }
    }
    return capabilities;
  }

  render() {
    if (this.state.matched) {
      return (<div id="capability" className="pagewrap">
        <Helmet>
          <title>{this.state.post.meta_title}</title>
          <link rel="canonical" href={"https://www.gritdigitalhealth.com/capabilities/" + this.state.post.post_name}/>
          <meta name="description" content={this.state.post.meta_description}/>
          <meta name="twitter:card" value="summary"/>
          <meta property="og:title" content={this.state.post.meta_title}/>
          <meta property="og:type" content="article"/>
          <meta property="og:url" content={"https://www.gritdigitalhealth.com/capabilities/" + this.state.post.post_name}/>
          <meta property="og:image" content={this.state.post.social_image}/>
          <meta property="og:description" content={this.state.post.meta_description}/>
        </Helmet>
        <div className="hero-wrap">
          <div className={'content-wrap'}>
            <div className="type-wrap">
              <Link to={'/capabilities'} className="crumb-link">
                <span>Capabilities</span>
              </Link><br/>
              <h1 className="styled">
                {this.state.post.post_title}
              </h1>
            </div>
          </div>
          <div className="desktop-img hero"><img src={this.state.post.hero_background.sizes.Maximum} alt="hero"/></div>
          <div className="mobile-img hero"><img src={this.state.post.hero_background_mobile.sizes.medium_large} alt="mobile hero"/></div>
        </div>
        <div className="description hidden">
          <div className="cac-pannel-wrap right">
            <div className="cac-pannel"></div>
          </div>
          <div className="content-wrap">
            <div className="body">
              <div className="mainbody" dangerouslySetInnerHTML={{
                  __html: this.state.post.post_content
                }}/>
              <div className="item-list">
                <ul>
                  {this.getItems()}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="other_capabilities hidden">
          <div className="content-wrap">
            <h2 className="title">Our Other Capabilities</h2>
            <div className="capabilities_list">
              <div className="caps-list">
                <div className="caps-slide">
                  {this.getCapabilities()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
    } else {
      return (<div id="team_member">
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        Page Not Found
      </div>);
    }
  }
}

export default Capability;
