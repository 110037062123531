import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

class FooterCallout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calloutData: this.props.calloutData,
    };
  }

  render() {
    var data = this.state.calloutData;
    return (
      <div className={"FooterCallout hidden"}>
        <div className="content">
          <h3>{data.headline}</h3>
          <Link to={data.link_destination.post_name} className="text-link">{data.call_to_action}</Link>
        </div>
      </div>
    )
  }
}

export default FooterCallout;
