import { Helmet } from 'react-helmet'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { data } from '../RecipesIndex/data'
import { RecipeCard } from '../RecipesIndex/card'
import { Footer } from '../RecipesIndex/footer'
import back from '../RecipesIndex/img/back-arrow.svg'
import gritLogo from '../RecipesIndex/img/grit-logo.svg'
import gritHeader from '../RecipesIndex/img/grit-digital-header.svg'
import { Share } from '../RecipesIndex/share'
import '../RecipesIndex/common.scss'
import './style.scss'
import starsGroupTwo from '../RecipesIndex/img/stars-group-2.png'
import starsGroupThree from '../RecipesIndex/img/stars-group-3.png'
import bigStar from '../RecipesIndex/img/big-star.png'

const Recipe = props => {
  const item = data.find(o => o.slug === props.match.params.title)

  if (!item) {
    return <h1>Item Not Found</h1>
  }

  const category = item['Recipe Category']
  const shuffle = arr => {
    return arr.sort(() => Math.random() - 0.5)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const moreRecipes = useMemo(() => {
    return shuffle(data)
      .filter(o => o.slug !== item.slug)
      .filter(o => o['Recipe Category'] === category)
      .slice(0, 3)
      .map((item, index) => {
        return (
          <RecipeCard
            key={index}
            title={item['Recipe Title'].trim()}
            time={item['Estimated Time to Complete']}
            category={item['Recipe Category']}
            src={item['src']}
          />
        )
      })
  }, [category, item.slug])

  const env = process.env.ENVIRONMENT ? process.env.ENVIRONMENT : 'staging'

  return (
    <div id="recipe">
      <Helmet>
        <title>{`Grit Holiday Recipes | ${item['Recipe Title'].trim()}`}</title>
        <link
          rel="canonical"
          href={`https://www.gritdigitalhealth.com/recipes/${props.match.params.title}`}
        />
        <meta
          name="description"
          content={props.initData.recipes.meta_description}
        />
        <meta name="twitter:card" value="summary" />
        <meta
          property="og:title"
          content={`Grit Holiday Recipes | ${item['Recipe Title'].trim()}`}
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`https://www.gritdigitalhealth.com/recipes/${props.match.params.title}`}
        />
        <meta
          property="og:image"
          content={props.initData.recipes.social_image}
        />
        <meta
          property="og:description"
          content={props.initData.recipes.meta_description}
        />
      </Helmet>
      <div className="page-texture"></div>

      <div className="flourish-wrap">
        <div className="flourish top right"></div>
        <div className="flourish bottom left"></div>
        <div className="flourish bottom right"></div>
        <div className="rect"></div>

        <header>
          <div className="container">
            <div className="navigation">
              <Link to="/recipes" className="back">
                <img src={back} alt="back" height="12px" width="16px" />
              </Link>
              <Share
                title="Share Website"
                href={`http://www.facebook.com/sharer/sharer.php?u=https://${env === 'staging' ? 'gritdev.netlify.app' : 'gritdigitalhealth.com'}/recipes/${props.match.params.title}`}
              />
              <div className="mobile-placeholder"></div>
            </div>
            <Link to="/recipes">
              <h1>
                <img
                  src={gritHeader}
                  height="170px"
                  alt="grit holiday recipes"
                />
              </h1>
            </Link>
          </div>
        </header>

        <main>
          <section className="recipe-breakdown">
            <div className="container">
              <div className="left">
                <div className="img-wrap">
                  <img src={item['src']} alt={item['Recipe Title']} />
                </div>

                <div className="ingredients">
                  <div className="flourish top left"></div>
                  <div className="flourish top right"></div>
                  <div className="flourish bottom left"></div>
                  <div className="flourish bottom right"></div>
                  <div className="rect"></div>

                  <div className="title">Ingredients</div>
                  <div
                    className="list"
                    dangerouslySetInnerHTML={{
                      __html: item['Ingredients List (with measurements)']
                    }}
                  ></div>
                </div>
              </div>

              <div className="right">
                <div className="group author">
                  <h2>Recipe by {item['Name']}</h2>
                  <div className="role">{item['Job Title']}</div>
                </div>

                <div className="group">
                  <h2>Why I Love This Recipe</h2>
                  <div className="indent">
                    {
                      item[
                        'Brief Description of Why You Love This Recipe (1-2 sentences)'
                      ]
                    }
                  </div>
                </div>

                <div className="group mobile">
                  <h2>Ingredients</h2>
                  <div
                    className="indent"
                    dangerouslySetInnerHTML={{
                      __html: item['Ingredients List (with measurements)']
                    }}
                  ></div>
                </div>

                <div className="group">
                  <h2>Directions</h2>
                  <div
                    className="indent"
                    dangerouslySetInnerHTML={{
                      __html: item['Recipe Instructions (step-by-step process)']
                    }}
                  ></div>
                </div>

                <div className="group">
                  <h2>My Favorite Holiday Cooking Song</h2>

                  <div
                    className="indent bold capitalize"
                    dangerouslySetInnerHTML={{
                      __html:
                        item[
                          'Favorite Winter Song: Name + Artist (for accompanying Spotify playlist)'
                        ]
                    }}
                  ></div>
                </div>
                <Share
                  color="white"
                  href={`http://www.facebook.com/sharer/sharer.php?u=https://${env === 'staging' ? 'gritdev.netlify.app' : 'gritdigitalhealth.com'}/recipes/${props.match.params.title}`}
                />
              </div>
            </div>
          </section>
        </main>
      </div>

      <section className="more-recipes">
        <div className="container">
          <div className="stars" style={{ top: '6vh', left: '-6%' }}>
            <img src={bigStar} alt="" height="48px" width="48px" />
          </div>
          <div className="stars" style={{ top: '4vh', right: '-7%' }}>
            <img src={starsGroupTwo} alt="" height="95px" width="115px" />
          </div>
          <div className="stars" style={{ bottom: '10vh', left: '-8%' }}>
            <img src={starsGroupThree} alt="" height="110px" width="83px" />
          </div>
          <div className="stars" style={{ bottom: '10vh', right: '-6%' }}>
            <img src={bigStar} alt="" height="48px" width="48px" />
          </div>
          <div className="title">
            <h2>More {category} Recipes from Grit Digital Health</h2>
          </div>

          <div className="cards">{moreRecipes}</div>

          <div className="button-wrap">
            <Link
              to={`/recipes?cat=${item['Recipe Category'].toLowerCase()}`}
              className="button active view-more"
            >
              View More {item['Recipe Category']} Recipes
            </Link>
          </div>
        </div>
      </section>

      <Footer href="https://gritdigitalhealth.com" logo={gritLogo} />
    </div>
  )
}

export default Recipe
