import React, { Component } from "react";
import "./style.scss";
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor(props) {
    super();
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  getYear() {
    const d = new Date();
    return d.getFullYear();
  }

  render() {
    return (
      <footer>
        <div className="foot-wrap">
          <nav>
            <div className="nav-wrap">
              <div className="social desktop">
                <span>Grit Social.</span>
                <a
                  href="https://www.linkedin.com/company/gritdigitalhealth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="twitter" src="/img/Grit_LinkedIn_Icon.svg" />
                </a>
                <a
                  href="https://fb.me/GritDigitalHealth"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="facebook" src="/img/Grit_Facebook_Icon.svg" />
                </a>
                <a
                  href="https://www.instagram.com/gritdigitalhealth/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="instagram" src="/img/Grit_Instagram_Icon.svg" />
                </a>
                <a
                  href="https://twitter.com/grit_digital"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="twitter" src="/img/Grit_Twitter_Icon.svg" />
                </a>
              </div>
              <div className="large-nav">
                <ul>
                  <li>
                    <Link to={"/contact"}>Contact</Link>
                  </li>
                  <li>
                    <Link to={"/solutions"}>Solutions</Link>
                  </li>
                  <li>
                    <Link to={"/about"}>About Us</Link>
                  </li>
                  {/*<li><Link to={'/capabilities'}>Capabilities</Link></li>*/}
                </ul>
              </div>
              <div className="small-nav">
                <ul>
                  <li>
                    <Link to={"/team"}>Our Team</Link>
                  </li>
                  <li>
                    <Link to={"/ideas"}>News</Link>
                  </li>
                  <li>
                    <Link to={"/careers"}>Careers</Link>
                  </li>
                  <li>
                    <Link to={"/contact"}>Hire Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="signup-wrap">

              <div className="link-wrap">

                  Contact <br></br>

                    <a
                      href="mailto:inquiries@gritdigitalhealth.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className='text-link'
                    >
                      inquiries@gritdigitalhealth.com{" "}
                    </a>
                    <div className='info'>  for more information on our products</div>





              </div>

              {/* <form
                action="https://cactusdenver.us12.list-manage.com/subscribe/post"
                method="POST"
              >
                <input
                  type="hidden"
                  name="u"
                  value="5d33935a9e366b1f117ece428"
                />
                <input type="hidden" name="id" value="5d8ecbbf9c" />
                <div className="title-wrap">
                  <label>Want to join our newsletter?</label>
                </div>
                <input
                  className="email"
                  name="MERGE0"
                  id="MERGE0"
                  type="email"
                  value={this.state.value}
                  onChange={this.handleChange}
                  placeholder="enter your email"
                />
                <div className="button">
                  <input
                    type="submit"
                    value="Join Newsletter"
                    className="submit"
                  />
                </div>
              </form> */}
            </div>
            <div className="social mobile">
              <span>Grit Social.</span>
              <a
                href="https://www.linkedin.com/company/gritdigitalhealth"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="twitter" src="/img/Grit_LinkedIn_Icon.svg" />
              </a>
              <a
                href="https://fb.me/GritDigitalHealth"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="facebook" src="/img/Grit_Facebook_Icon.svg" />
              </a>
              <a
                href="https://www.instagram.com/gritdigitalhealth/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="instagram" src="/img/Grit_Instagram_Icon.svg" />
              </a>
              <a
                href="https://twitter.com/grit_digital"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="twitter" src="/img/Grit_Twitter_Icon.svg" />
              </a>
            </div>
          </nav>
          <div className="legal">
            © {this.getYear()} Grit Digital Health, LLC.{" "}
            <Link to="/terms-of-use">Terms of Use</Link>{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
