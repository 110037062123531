import React from "react";
import { Link } from "react-router-dom";

export const Footer = ({ logo, href }) => {
  return (
    <Link to={href} target="_blank" rel="noopener noreferrer">
      <footer>
        <div className="container flex">
          <p>From your friends at </p>
          <img
            src={logo}
            height="24px"
            width="auto"
            alt="grit digital health"
          />
        </div>
      </footer>
    </Link>
  );
};
