import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom';
import FooterCallout from '../FooterCallout';
import Collapsible from 'react-collapsible';
import './style.scss';

class SpeakerPage extends Component {

  constructor() {
    super();
    this.state = {

    };
  }

  getSpeakers(){
    const speakerData = this.props.initData.speakers;
    var speakers = [];
    for (var speaker in speakerData) {
      var topics = speakerData[speaker].presentation_topics;
      var pastPres = speakerData[speaker].past_presentations;
      speakers.push(
        <div className="speaker-wrap hidden" key={speaker}>
          <div className="image">
            <img src={speakerData[speaker].speaker_image.sizes.large} alt={speakerData[speaker].speaker_image.title} />
          </div>
          <div className="content">
            <h2>{speakerData[speaker].name}</h2>
            <p className="title">{speakerData[speaker].speaker_title}</p>
            <p className="body" dangerouslySetInnerHTML={{
                __html: speakerData[speaker].descriptive_text
              }}/>
            <a href="/contact" className="button">Schedule an Event</a>
              <div className="topics">
                <h3>Presentation Topics</h3>
                <ul>
                {topics.map((topic, index) => (
                    <li key={index}>{topic.topic_name}</li>
                ))}
                </ul>
              </div>
              <Collapsible easing="ease" trigger="Selected Past Presentations">
                {pastPres.map((pres, index) => (
                    <div key={index} className="presentationGroup">
                      <h4>{pres.name}</h4>
                      <strong>{pres.title}</strong>
                      <p>{pres.date} - {pres.location}</p>
                    </div>
                ))}
              </Collapsible>
          </div>
        </div>
      );
    }
    return speakers;
  }

  render() {
    return (<div id="SpeakerPage" className="pagewrap">
      <Helmet>
        <title>{this.props.initData.SpeakerPage.meta_title}</title>
        <link rel="canonical" href="https://www.gritdigitalhealth.com/speaking-engagements"/>
        <meta name="description" content={this.props.initData.SpeakerPage.meta_description}/>
        <meta name="twitter:card" value="summary"/>
        <meta property="og:title" content={this.props.initData.SpeakerPage.meta_title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content="https://www.gritdigitalhealth.com/speaking-engagements"/>
        <meta property="og:image" content={this.props.initData.SpeakerPage.social_image}/>
        <meta property="og:description" content={this.props.initData.SpeakerPage.meta_description}/>
      </Helmet>
      <div className='content-wrap'>
        <div className="crumb">
          <span>About</span>
        </div><br/>
        <Link to={'/'} className="button">Schedule an Event</Link>
        <h1>
          {this.props.initData.SpeakerPage.headline}
        </h1>
        <h3>{this.props.initData.SpeakerPage.subhead}</h3>
      </div>
      <div className="speakers-wrap">
        {this.getSpeakers()}
      </div>
      <FooterCallout calloutData={this.props.initData.SpeakerPage.footer_callout} />
    </div>);
  }
}

export default SpeakerPage;
