var pageHistory = [];

function ScrollUp() {
  //set normal page navigation var
  let onPopstate = "false";
  //if it's a popstate change that var to true
  window.onpopstate = function(event) {
    event.preventDefault();
    onPopstate = "true";
  };
  //wait until the popstate has time to set the var
  setTimeout(function() {
    handleScroll(onPopstate); //call scroll position function based on popstate var.
  }, 250);
  //logic for scroll position based on whether it's a popstate
  var handleScroll = function(value) {
    if (value === "false") { //it's a normal page transition
      normalScroll();
      //console.log('normal');
    } else if (value === "true") { //it's a popstate transition (Back or Forward in the browser)
      popScroll();
      //console.log('pop');
    }
  }
  var pageLocation = document.documentElement.scrollTop || document.body.scrollTop;
  var historyLength = pageHistory.length;

  var normalScroll = function() { //scroll back to the top of the document on a normal transition
    setTimeout(function() {
      const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
          window.requestAnimationFrame(scrollToTop);
          window.scrollTo(0, 0);
        }
      };
      scrollToTop();
    }, 250);
    pageHistory.push(pageLocation);
  }

  var popScroll = function() { //scroll to the last stored page location
    //console.log(pageHistory);
    window.scroll({top: pageHistory[historyLength], left: 0, behavior: 'smooth'});
    pageHistory.push(pageLocation);
  }

}

export default ScrollUp;
