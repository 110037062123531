import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Helmet } from "react-helmet";
import { unregister } from "./registerServiceWorker"; //fix for sitemap.xml not rendering without a hard refresh. Created a soft 404.
import PardotSnippet from "./components/PardotSnippet";
import "./App.scss";
import "./global.scss";

//Sub-Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import News from "./components/News";
import Work from "./components/Work";
import About from "./components/About";
import Team from "./components/Team";
import MarketingToolkit from "./components/MarketingToolkit";
import Contact from "./components/Contact";
import CapabilitiesPage from "./components/CapabilitiesPage";
import Capability from "./components/Capability";
import CaseStudy from "./components/CaseStudy";
import Careers from "./components/Careers";
import TeamMember from "./components/TeamMember";
import Gratitude from "./components/Gratitude";
import JobListing from "./components/JobListing";
import Posts from "./components/Posts";
import Industries from "./components/Industries";
import NotFound from "./components/404";
import ScrollUp from "./components/ScrollUp";
import SpeakerPage from "./components/SpeakerPage";
import Partnerships from "./components/Partnerships";
import StaticPage from "./components/StaticPage";
import RecipesIndex from "./components/RecipesIndex";
import Recipe from "./components/Recipe";
import Tips from "./components/Tips";
import Tip from "./components/Tip";

//import Nod19Footer from './components/Nod19Footer';
import Terms from "./components/Terms";
import Privacy from './components/PrivacyPolicy'

class App extends Component {
  constructor() {
    super();
    this.state = {
      hasInitData: false,
      initData: {},
      ready: false,
    };
  }

  componentDidMount() {
    //Get the environment
    const env = process.env.ENVIRONMENT ? process.env.ENVIRONMENT : "staging";
    console.log('environment:', env);

    //Choose json file based on environment
    var url =
      "https://grit-digital-health.s3.amazonaws.com/site-json/production-initData.json";
    if (env === "staging")
      url =
        "https://grit-digital-health.s3.amazonaws.com/site-json/staging-initData.json";

    //Load json w/ a timestamp for caching
    var version = "";
    if (env === "production") version = "?v=" + process.env.BUILD_TIMESTAMP;
    const loadurl = url + version;
    var mythis = this;
    fetch(loadurl)
      .then((response) => response.json())
      .then(function(data) {
        // console.log('initData_unsorted:', data);
        data = mythis.sortInitData(data);
        if (env === "staging") console.log("initData_sorted:", data);
        mythis.setState({ hasInitData: true, initData: data });
        mythis.dataListener();
      });
    this.animateEnter();
    this.dataListener();
    unregister(); //fix for sitemap.xml not displaying without a hard refresh.

    // used for news page to get paginated posts -
    // necessary because cms server is upaid and falls asleep
    fetch(
      "https://test-grit-cms.pantheonsite.io/wp-json/wp/v2/categories"
    ).then((response) => {
      // console.log('wake up');
      return response.json();
    });

    //Remove old local storage items
    if (localStorage.getItem("@nod19footerhidden"))
      localStorage.removeItem("@nod19footerhidden");
  }

  animateEnter() {
    var appWrapper = document.getElementById("app");
    setTimeout(function() {
      appWrapper.classList.remove("enter");
    }, 3500);
  }

  animateTransition() {
    var appWrapper = document.getElementById("app");
    if (this.state.navclass === "active") {
      //do a whole lot of nothing
    } else {
      appWrapper.classList.add("active");
      setTimeout(function() {
        appWrapper.classList.remove("active");
      }, 1000);
    }
  }

  componentDidUpdate(prevProps) {
    ScrollUp();
    this.animateTransition();
    setTimeout(() => {
      this.fadeInElements().init();
    }, 1000);
  }

  fadeInElements() {
    var elems;
    var windowHeight;
    function init() {
      elems = document.querySelectorAll(".hidden");
      windowHeight = window.innerHeight;
      addEventHandlers();
      checkPosition();
    }
    function addEventHandlers() {
      window.addEventListener("scroll", checkPosition);
      window.addEventListener("resize", init);
    }
    function checkPosition() {
      for (var i = 0; i < elems.length; i++) {
        var positionFromTop = elems[i].getBoundingClientRect().top;
        if (positionFromTop - windowHeight + 75 <= 0) {
          elems[i].className = elems[i].className.replace(
            "hidden",
            "fade-in-element"
          );
        }
      }
    }
    return { init: init };
  }

  dataListener() {
    if (!this.state.hasInitData) {
      //do a whole lotta nothin
    } else {
      //fade the loader out and set the app state to ready so everything loads in.
      this.setState({ ready: true });
    }
  }

  sortInitData(data) {
    //Mappings to post ID's
    const HOME_PAGE_POST_ID = 2;
    const WORK_PAGE_POST_ID = 7;
    const ABOUT_PAGE_POST_ID = 50;
    const CAPABILITIES_PAGE_POST_ID = 52;
    const CONTACT_PAGE_POST_ID = 54;
    const NEWS_PAGE_POST_ID = 56;
    const CAREERS_PAGE_POST_ID = 58;
    const TEAM_PAGE_POST_ID = 807;
    const SPEAKERS_PAGE_ID = 1374;
    const PARTNERSHIPS_PAGE_ID = 1394;
    const TERMS_PAGE_ID = 1840;
    const RECIPES_PAGE_POST_ID = 2050;
    const TIPS_PAGE_POST_ID = 2208;
    const PRIVACY_PAGE_ID = 2419

    //Data Structure
    var data_sorted = {
      home: {},
      work: {},
      about: {},
      team: {},
      contact: {},
      news: {},
      marketing_toolkit: {},
      capabilities_page: {},
      capabilities: {},
      careers: {},
      industries: {},
      case_studies: {},
      job_listings: {},
      team_members: {},
      products: {},
      SpeakerPage: {},
      speakers: {},
      partnerships: {},
      partners: {},
      privacy: {},
      terms: {},
      tips: {},
      tips_page: {},
    };

    //Organize Data
    data.forEach((post) => {
      switch (post.post_type) {
        case "page":
          if (post.ID === HOME_PAGE_POST_ID) data_sorted.home = post;
          if (post.ID === WORK_PAGE_POST_ID) data_sorted.work = post;
          if (post.ID === ABOUT_PAGE_POST_ID) data_sorted.about = post;
          if (post.ID === TEAM_PAGE_POST_ID) data_sorted.team = post;
          if (post.ID === CONTACT_PAGE_POST_ID) data_sorted.contact = post;
          if (post.ID === CAPABILITIES_PAGE_POST_ID)
            data_sorted.capabilities_page = post;
          if (post.ID === SPEAKERS_PAGE_ID) data_sorted.SpeakerPage = post;
          if (post.ID === PARTNERSHIPS_PAGE_ID) data_sorted.partnerships = post;
          if (post.ID === NEWS_PAGE_POST_ID) data_sorted.news = post;
          if (post.ID === CAREERS_PAGE_POST_ID) data_sorted.careers = post;
          if (post.ID === TERMS_PAGE_ID) data_sorted.terms = post;
          if (post.ID === RECIPES_PAGE_POST_ID) data_sorted.recipes = post;
          if (post.ID === TIPS_PAGE_POST_ID) data_sorted.tips_page = post;
          if (post.ID === PRIVACY_PAGE_ID) data_sorted.privacy = post;
          break;
        case "case_study":
          data_sorted.case_studies[post.post_name] = post;
          break;
        case "products":
          data_sorted.products[post.post_name] = post;
          break;
        case "careers":
          data_sorted.job_listings[post.post_name] = post;
          break;
        case "capabilities":
          data_sorted.capabilities[post.post_name] = post;
          break;
        case "experience":
          data_sorted.industries[post.post_name] = post;
          break;
        case "team_member":
          data_sorted.team_members[post.post_name] = post;
          break;
        case "speakers":
          data_sorted.speakers[post.post_name] = post;
          break;
        case "partners":
          data_sorted.partners[post.post_name] = post;
          break;
        case "marketing_toolkit":
          data_sorted.marketing_toolkit[post.post_name] = post;
          break;
        case "tips":
          data_sorted.tips[post.post_name] = post;
          break;
        default:
      }
    });
    return data_sorted;
  }

  render() {
    if (!this.state.ready) {
      return (
        <div className="app" id="app">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Grit Digital Health</title>
          </Helmet>
        </div>
      );
    } else {
      const reload = () => window.location.reload();
      return (
        <div className="app enter" id="app">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Grit Digital Health</title>
            <link
              rel="canonical"
              href="https://www.gritdigitalhealth.com/"
            />{" "}
            {/* font awesome for icons */}
          </Helmet>
          {/*<Nod19Footer /> btw local storage item gets removed above in componentDidMount */}

          {/* render header on every page except /recipes */}

          {window.location.pathname.includes("/recipes") ||
          window.location.pathname.includes("/tips") ? (
            ""
          ) : (
            <Header />
          )}

          <div className="main">
            <Route
              render={({ location }) => (
                <ReactCSSTransitionGroup
                  transitionName="PageTransition"
                  transitionAppear={true}
                  transitionAppearTimeout={1100}
                  transitionEnterTimeout={700}
                  transitionLeaveTimeout={300}
                  className="page-transitions"
                >
                  <Switch key={location.key} location={location}>
                    {/* Routes */}
                    <Route
                      exact
                      path="/"
                      component={(props) => {
                        return (
                          <Home initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/speaking-engagements"
                      component={(props) => {
                        return (
                          <SpeakerPage
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/partners"
                      component={(props) => {
                        return (
                          <Partnerships
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/solutions"
                      component={(props) => {
                        return (
                          <Work initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/work/:case_study"
                      component={(props) => {
                        return (
                          <CaseStudy
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/products/:products"
                      component={(props) => {
                        return (
                          <CaseStudy
                            caseType="products"
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/Industries/:industry"
                      component={(props) => {
                        return (
                          <Industries
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/about"
                      component={(props) => {
                        return (
                          <About initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/team"
                      component={(props) => {
                        return (
                          <Team initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/team/:team_member"
                      component={(props) => {
                        return (
                          <TeamMember
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/contact"
                      component={(props) => {
                        return (
                          <Contact initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/careers"
                      component={(props) => {
                        return (
                          <Careers initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/careers/:job_listing"
                      component={(props) => {
                        return (
                          <JobListing
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/capabilities"
                      component={(props) => {
                        return (
                          <CapabilitiesPage
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/capabilities/:capability"
                      component={(props) => {
                        return (
                          <Capability
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/gratitude"
                      component={(props) => {
                        return (
                          <Gratitude
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/marketing-toolkits/:toolkit"
                      component={(props) => {
                        return (
                          <MarketingToolkit
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/ideas"
                      component={(props) => {
                        return (
                          <News initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/ideas/:posts"
                      component={(props) => {
                        return (
                          <Posts initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/university-of-oregon"
                      component={(props) => {
                        return (
                          <StaticPage
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/terms-of-use"
                      component={(props) => {
                        return (
                          <Terms initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/privacy-policy"
                      component={props => <Privacy initData={this.state.initData} {...props} />}
                    />
                    <Route
                      exact
                      path="/recipes"
                      component={(props) => {
                        return (
                          <RecipesIndex
                            initData={this.state.initData}
                            {...props}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/recipes/:title"
                      component={(props) => {
                        return (
                          <Recipe initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/tips"
                      component={(props) => {
                        return (
                          <Tips initData={this.state.initData} {...props} />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/tips/:title"
                      component={(props) => {
                        return (
                          <Tip initData={this.state.initData} {...props} />
                        );
                      }}
                    />

                    <Route path="/sitemap.xml" render={reload} />

                    <Route
                      path="/sitemap/"
                      render={() => (
                        <Redirect
                          to={{
                            pathname: "/sitemap.xml",
                          }}
                        />
                      )}
                    />

                    {/* Redirects */}
                    <Redirect from="/product/" to="/solutions" />
                    <Redirect from="/newsroom/" to="/ideas" />
                    <Route component={NotFound} />
                  </Switch>
                </ReactCSSTransitionGroup>
              )}
            />
          </div>
          {/* render footer on every page except /gratitude */}
          {/* Conditionally render Footer */}
          {window.location.pathname === "/gratitude-generator" ||
          window.location.pathname.includes("/recipes") ||
          window.location.pathname.includes("/tips") ? (
            ""
          ) : (
            <Footer />
          )}
          <PardotSnippet />
        </div>
      );
    }
  }
}

export default App;
